<div class="flex flex-col overflow-y-auto overflow-x-hidden w-[130px] h-full menu-bg relative text-left ltr:border-r ltr:border-r-gray-300 rtl:border-l rtl:border-l-gray-300">
  <div class="flex flex-col text-base flex-auto px-[10px] py-[20px]">
    <div class="flex flex-col flex-auto justify-between">
      <div>
        <ng-container *ngTemplateOutlet="loggedMenu"></ng-container>
      </div>
      <div class="flex flex-col justify-center items-center">
<!--        <div class="menu-item w-full" (click)="showInviteQRCode()">-->
<!--          <div class="menu-icon">-->
<!--            <ion-icon name="person-add-outline" class="text-2xl"></ion-icon>-->
<!--          </div>-->
<!--        </div>-->
        <app-initials-avatar class="cursor-pointer mt-4" [avatarId]="me()?.avatarId" [user]="{name: me()?.name}" [width]="60" [textSize]="'text-base'" (click)="openProfile()"></app-initials-avatar>
      </div>
    </div>
  </div>
</div>

<ng-template #loggedMenu>
  <div class="menu-item" (click)="joinRoomByQR()">
    <div class="menu-icon"><ion-icon name="qr-code-outline" class="text-2xl"></ion-icon></div>
    <div class="label">{{ 'button.join.conversation' | transloco }}</div>
  </div>
  <div class="menu-item" [class.active]="_roomService.mode() === RoomMode.SINGLE" (click)="startChat(RoomMode.SINGLE)">
    <div class="menu-icon">
      @if (pending[RoomMode.SINGLE]) {
        <ion-icon name="sync-outline" class="text-2xl spin-animation"></ion-icon>
      } @else {
        <ion-icon name="chatbubble-outline" class="text-2xl"></ion-icon>
      }
    </div>
    <div class="label">{{ 'button.chat.mode.single' | transloco }}</div>
  </div>
  <div class="menu-item" [class.active]="_roomService.mode() === RoomMode.MULTI" (click)="startChat(RoomMode.MULTI)">
    <div class="menu-icon">
      @if (pending[RoomMode.MULTI]) {
        <ion-icon name="sync-outline" class="text-2xl spin-animation"></ion-icon>
      } @else {
        <ion-icon name="chatbubbles-outline" class="text-2xl"></ion-icon>
      }
    </div>
    <div class="label">{{ 'button.chat.mode.conference' | transloco }}</div>
  </div>
  <div class="menu-item" (click)="openRoomsHistory()">
    <div class="menu-icon"><ion-icon src="/assets/icons/awesome/icon-history.svg" class="text-2xl"></ion-icon></div>
    <div class="label">{{ 'button.conversation.history' | transloco }}</div>
  </div>
  @if ([ UserRole.COMPANY_USER, UserRole.COMPANY_HOST, UserRole.COMPANY_ADMIN ].indexOf(me()?.role) >= 0) {
    <div class="menu-item" (click)="navigate()">
      <div class="menu-icon"><i class="fa-regular fa-comments fa-lg"></i></div>
      <div class="label">{{ 'button.chats' | transloco }}</div>
    </div>
  }
</ng-template>
