<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>
      <div class="cursor-pointer overflow-hidden" (click)="showRoomInfo()">
        @if (history?.room?.hostRoom) {
          <div class="title text-2xl text-center font-bold truncate">{{ history?.room?.hostRoom.name }}</div>
        } @else {
          <div class="title text-2xl text-center font-bold truncate">{{ history?.room?.creator?.name }}</div>
        }
        <div class="flex items-center text-sm justify-center">
          <!--                <div class="h-[18px] overflow-hidden">{{ 'label.participants' |  transloco }}</div>-->
          <!--                pl-2.5 ml-2.5 border-l border-l-gray-300-->
          <div class="flex items-center whitespace-nowrap">
            <ion-icon name="calendar-outline" class="text-gray-400"></ion-icon>
            <div class="ltr:ml-1.5 rtl:mr-1.5 normal-text leading-none">{{ history?.room?.createTime | prettyDate | async }}</div>
          </div>
        </div>
      </div>
    </ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="overflow-hidden w-full h-full flex-auto flex flex-col">
  @if (loading) {
    <app-loader></app-loader>
  }
  <div class="ai-chat-container flex flex-col w-full flex-auto min-w-0 sm:rounded-xl shadow-2xl dark:shadow-none overflow-hidden relative">
    <div class="w-full flex-auto flex justify-center overflow-hidden multi-mode">
      <div class="flex flex-col w-full">
        <div class="ai-chat flex flex-auto flex-col bg-card dark:bg-default overflow-hidden">
          <div class="flex flex-col flex-auto pt-4 pb-4 pl-4 pr-4 chat-container-bg overflow-scroll">
            <div class="flex flex-col flex-auto relative">
              <ion-content scroll-y="true" #scrollArea>
                <ion-infinite-scroll position="top" (ionInfinite)="loadMoreMessages($event)" [disabled]="empty">
                  <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
                </ion-infinite-scroll>
                <ng-container *ngTemplateOutlet="multiModeChat"></ng-container>
              </ion-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template let-lang let-creator="creator" let-muted="muted" let-avatarId="avatarId" #avatarLetters>
  <app-initials-avatar [avatarId]="avatarId" [user]="{name: lang}" [muted]="muted" [creator]="creator" [width]="30" [textSize]="'text-xs'"></app-initials-avatar>
</ng-template>

<ng-template #chatMessageMultiple let-t>
  <div class="flex relative items-start max-w-full mb-4" [class.inactive-msg]="copyTime && copyTime !== t.time" [ngClass]="{'justify-end': t.userId === me().id}">
    @if (t.userId !== me().id) {
      <div class="lang ltr:mr-2 rtl:ml-2">
        <ng-container *ngTemplateOutlet="avatarLetters; context: {$implicit: t.name, avatarId: t.avatarId}"></ng-container>
      </div>
    }
    <div
      class="flex flex-col max-w-[70%] overflow-hidden"
      [ngClass]="{'items-end': t.userId === me().id, 'items-start': t.userId !== me().id}">
      @if (t.userId !== me().id) {
        <div class="text-xl title font-semibold truncate w-full">{{ t.name }}</div>
      }
      <div
        class="relative min-w-44 w-full px-3 pb-2 pt-2.5"
        [ngClass]="{'chat-my-msg-bg text-white': t.userId === me().id, 'chat-msg-bg': t.userId !== me().id}">

        @if (t.photo) {
          <div class="thumb-photo-container mb-2">
            <img [src]="environment.serverUrl + '/v1/photos/' + t.photo" alt="" class="photo" (click)="showImage(environment.serverUrl + '/v1/photos/' + t.photo)"/>
          </div>
        }

        @if (t.userId === me().id || showOriginalTexts[t.time]) {
          <div class="leading-5 wrap text-base chat-msg" [innerHTML]="t.originalText"></div>
        } @else {
          @if (me().language === t.originalLang) {
            <div class="leading-5 wrap text-base chat-msg" [innerHTML]="t.originalText"></div>
          } @else {
            <div class="leading-5 wrap text-base chat-msg" [innerHTML]="t.szdMessages && t.szdMessages[t.users[me().id]] || ''"></div>
          }
        }

        <div class="flex justify-between items-center mt-2.5">
          <div class="text-xs chat-msg-date-color">
            @if (showOriginalTexts[t.time]) {
              {{ t.originalLang | transloco }}
            } @else {
              {{ (t.time * 1000) | date: 'HH:mm' }}
            }
          </div>
          <div class="flex items-center relative justify-end min-w-1/2">
            @if (copyTime && copyTime === t.time) {
              <div
                class="bg-black text-white py-1.5 px-1 text-center text-xs absolute max-w-32 -translate-y-full -translate-x-0 left-0 right-0 m-auto">{{ 'text.copied' | transloco }}
              </div>
            }
            @if (t.userId !== me().id) {
              <ion-icon name="language-outline" (click)="showOriginalText(t)" [class.active]="showOriginalTexts[ t.time ]"
                        class="text-3xl rtl:ml-2 cursor-pointer"></ion-icon>
            }
            <ion-icon name="copy-outline" class="text-3xl rtl:mr-2 ltr:ml-2 cursor-pointer" (click)="copyText(t.originalLang, t.originalText, t.time)"
                      [class.active]="copyTime && copyTime === t.time"></ion-icon>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #chatMessageSingle let-t>
  <div class="flex relative items-start max-w-full mb-4" [class.inactive-msg]="copyTime && copyTime !== t.time" [ngClass]="{'justify-end': t.originalLang === t.users[me().id]}">
    <div
      class="flex flex-col flex-auto max-w-[70%] overflow-hidden"
      [ngClass]="{'items-end': t.originalLang === t.users[me().id], 'items-start': t.originalLang !== t.users[me().id]}">
      @if (t.originalLang !== t.users[me().id]) {
        <div class="text-xl title font-semibold truncate w-full capitalize">{{ 'label.interlocutor' | transloco }}</div>
      } @else {
        <div class="text-xl title font-semibold truncate w-full capitalize">{{ 'label.you' | transloco }}</div>
      }
      <div
        class="relative min-w-44 w-full px-3 pb-2 pt-2.5"
        [ngClass]="{'chat-my-msg-bg text-white': t.originalLang === t.users[me().id], 'chat-msg-bg': t.originalLang !== t.users[me().id]}">

        @if (t.photo) {
          <div class="thumb-photo-container mb-2">
            <img [src]="environment.serverUrl + '/v1/photos/' + t.photo" alt="" class="photo" (click)="showImage(environment.serverUrl + '/v1/photos/' + t.photo)"/>
          </div>
        }

        @if (showOriginalTexts[t.time]) {
          @if (t.users[me().id] === t.originalLang) {
            <div class="leading-5 wrap text-base chat-msg" [innerHTML]="t.szdMessages && getFirstValue(t.szdMessages) || ''"></div>
          } @else {
            <div class="leading-5 wrap text-base chat-msg" [innerHTML]="t.originalText"></div>
          }
        } @else {
          @if (t.users[me().id] === t.originalLang) {
            <div class="leading-5 wrap text-base chat-msg" [innerHTML]="t.originalText"></div>
          } @else {
            <div class="leading-5 wrap text-base chat-msg" [innerHTML]="t.szdMessages && t.szdMessages[t.users[me().id]] || ''"></div>
          }
        }

        <div class="flex justify-between items-center mt-2.5">
          <div class="text-xs chat-msg-date-color">
            @if (showOriginalTexts[t.time]) {
              @if (t.users[me().id] === t.originalLang) {
                {{ getFirstKey(t.szdMessages) | transloco }}
              } @else {
                {{ t.originalLang | transloco }}
              }
            } @else {
              {{ (t.time * 1000) | date: 'HH:mm' }}
            }
          </div>
          <div class="flex items-center relative justify-end min-w-1/2">
            @if (copyTime && copyTime === t.time) {
              <div
                class="bg-black text-white py-1.5 px-1 text-center text-xs absolute max-w-32 -translate-y-full -translate-x-0 left-0 right-0 m-auto">{{ 'text.copied' | transloco }}
              </div>
            }
            <ion-icon name="language-outline" (click)="showOriginalText(t)" [class.active]="showOriginalTexts[ t.time ]"
                      class="text-3xl rtl:ml-2 cursor-pointer"></ion-icon>

            <ion-icon name="copy-outline" class="text-3xl rtl:mr-2 ltr:ml-2 cursor-pointer" (click)="copyText(t.originalLang, t.originalText, t.time)"
                      [class.active]="copyTime && copyTime === t.time"></ion-icon>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #multiModeChat>
  @for (t of history?.messages?.list; track t; let first = $first; let last = $last; let i = $index) {

    @if (first || ((history?.messages?.list[i - 1].time * 1000) | date: 'd') !== ((t.time * 1000) | date: 'd')) {
      <div
        class="my-3 flex items-center justify-center"
      >
        <div class="flex-auto border-b border-b-gray-300"></div>
        <div
          class="text-secondary mx-4 flex-0 text-sm font-semibold leading-5"
        >
          {{
            (t.time * 1000) | date: 'dd.MM.yyyy'
          }}
        </div>
        <div class="flex-auto border-b border-b-gray-300"></div>
      </div>
    }

    @if (t.type === ChatMessageType.MESSAGE) {
      @if (roomMode === RoomMode.MULTI) {
        <ng-container *ngTemplateOutlet="chatMessageMultiple; context: {$implicit: t}"></ng-container>
      } @else {
        <ng-container *ngTemplateOutlet="chatMessageSingle; context: {$implicit: t}"></ng-container>
      }
    } @else if (t.type === ChatMessageType.USER_JOIN) {
      <ng-container *ngTemplateOutlet="userJoinedMessage; context: {$implicit: t}"></ng-container>
    } @else if (t.type === ChatMessageType.USER_LEFT) {
      <ng-container *ngTemplateOutlet="userLeftMessage; context: {$implicit: t}"></ng-container>
    }
  }
</ng-template>

<ng-template #userJoinedMessage let-message>
  <div class="w-full flex justify-between user-joined-message-bg py-1 px-1.5 text-sm mb-4 items-center">
    <div class="flex items-center">
      <div>
        <app-initials-avatar [bgColor]="'chat-avatar-bg-color'" [user]="{name: message.name}" [width]="20" [textSize]="'text-[8px]'"></app-initials-avatar>
      </div>
      <div class="ltr:ml-2 rtl:mr-2" [innerHTML]="'text.user.joined.chat' | transloco: {user: message.name}"></div>
    </div>
    <div class="time-color">{{ (message.time * 1000) | date: 'HH:mm' }}</div>
  </div>
</ng-template>

<ng-template #userLeftMessage let-message>
  <div class="w-full flex justify-between user-joined-message-bg py-1 px-1.5 text-sm mb-4 items-center">
    <div class="flex items-center">
      <div>
        <app-initials-avatar [bgColor]="'chat-avatar-bg-color'" [user]="{name: message.name}" [width]="20" [textSize]="'text-[8px]'"></app-initials-avatar>
      </div>
      <div class="ltr:ml-2 rtl:mr-2" [innerHTML]="'text.user.left.chat' | transloco: {user: message.name}"></div>
    </div>
    <div class="time-color">{{ (message.time * 1000) | date: 'HH:mm' }}</div>
  </div>
</ng-template>
