<ion-icon name="close-outline" class="text-4xl close-icon cursor-pointer" (click)="closeModal()"></ion-icon>

<div class="flex flex-col justify-center items-center self-center flex-auto p-5">
  <div class="title text-3xl">{{'title.qr.scan' | transloco}}</div>
  <div class="mt-2 mb-5">{{'text.qr.scan' | transloco}}</div>
  @if (loading) {
    <div class="w-full flex justify-center items-center">
      <app-loader></app-loader>
    </div>
  }
  <div class="flex flex-col justify-center items-center">
    <div id="reader" style="min-width: 330px"></div>
  </div>

</div>
