import {Component, inject, Input, OnInit, Signal} from '@angular/core';
import {Room, RoomParticipants, RoomUser, UserRole} from '@shared/transport.interface';
import {LoaderComponent} from '@shared/component/loader/loader.component';
import {NgClass, NgTemplateOutlet} from '@angular/common';
import {TranslocoPipe} from '@ngneat/transloco';
import {Store} from '@ngxs/store';
import {UserState} from '@shared/store/user/user-state.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {finalize} from 'rxjs';
import {Pages} from '@app/pages';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {DialogService} from '@shared/service/dialog.service';
import {NotyService} from '@shared/service/noty.service';
import {RoomService} from '@shared/service/room.service';
import {UserCardComponent} from '@shared/component/ui/user-card/user-card.component';
import {IonicModule} from '@ionic/angular';
import {addIcons} from 'ionicons';
import {ellipsisVertical} from 'ionicons/icons';

@UntilDestroy()
@Component({
  selector: 'app-room-participants',
  standalone: true,
  imports: [
    LoaderComponent,
    NgTemplateOutlet,
    TranslocoPipe,
    NgClass,
    UserCardComponent,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    IonicModule
  ],
  templateUrl: './room-participants.component.html',
  styleUrl: './room-participants.component.scss'
})
export class RoomParticipantsComponent implements OnInit {
  @Input() room: Room;
  @Input() readOnly: boolean = false;
  private _store = inject(Store);
  private _roomService = inject(RoomService);
  private _dialogService = inject(DialogService);
  private _noty = inject(NotyService);
  me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);

  protected roomParticipants: RoomParticipants;
  protected expand = false;
  protected loading = false;

  constructor() {
    addIcons({ellipsisVertical});
  }

  ngOnInit(): void {
    this.loading = true;
    this._roomService.roomParticipants(this.room.roomId)
      .pipe(untilDestroyed(this), finalize(() => this.loading = false))
      .subscribe(res => {
        this.roomParticipants = res;
      });
  }

  protected readonly parent = parent;
  protected readonly UserRole = UserRole;
  protected readonly Pages = Pages;

  blockUser(user: RoomUser): void {
    this._dialogService.blockParticipant(this.room, user).subscribe((res) => {
      if (res) {
        this._noty.success('message.user.blocked', {name: user.name});
        this.roomParticipants.blocked.push(user);
        const ind = this.roomParticipants.participants.findIndex(u => u.id === user.id);
        if (ind >= 0) {
          this.roomParticipants.participants.splice(ind, 1);
        }
      }
    });
  }

  blockedUsers(): void {
    if (this.readOnly) {
      return;
    }
    this._dialogService.blockedUsers(this.room, this.roomParticipants.blocked)
      .then(res => {
        if (res?.length) {
          res.forEach(user => {
            const ind = this.roomParticipants.blocked.findIndex(u => u.id === user);
            if (ind >= 0) {
              this.roomParticipants.participants.push(this.roomParticipants.blocked[ind]);
              this.roomParticipants.blocked.splice(ind, 1);
            }
          });
        }
      });
  }

  exitRoom(): void {
    this._dialogService.exitRoom(this.room.roomId)
      .subscribe(() => {
        this._dialogService.closeAll();
      });
  }
}
