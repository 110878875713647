import { Component, inject, Input, OnInit, signal } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UtcDatePipe } from '@pipe/utc-date.pipe';
import { Tariff, TariffPackage } from '@shared/transport.interface';
import { Platform } from '@ionic/angular';
import { InappPurchasesService } from '@service/inapp-purchases.service';
import { finalize } from 'rxjs';
import { Browser } from '@capacitor/browser';
import { NotyService } from "@service/noty.service";
import { UserService } from '@service/user.service';

@UntilDestroy()
@Component({
  selector: 'app-minutes-package-card',
  standalone: true,
  imports: [
    CurrencyPipe,
    TranslocoPipe,
    UtcDatePipe,
    DecimalPipe
  ],
  templateUrl: './minutes-package-card.component.html',
  styleUrl: './minutes-package-card.component.scss'
})
export class MinutesPackageCardComponent implements OnInit {
  @Input() pack: TariffPackage;
  @Input() tariff: Tariff;
  pending = false;
  isWeb = signal(false);
  isMobile = signal(false);

  private readonly _noty = inject(NotyService);
  private readonly _platform = inject(Platform);
  private readonly _inAppService = inject(InappPurchasesService);
  private readonly _userService = inject(UserService);

  ngOnInit(): void {
    if (this._platform.is('desktop') || this._platform.is('mobileweb')) {
      this.isWeb.set(true);
    }
    if (this._platform.is('capacitor') && !this._platform.is('mobileweb')) {
      this.isMobile.set(true);
    }
  }

  buyMinutes(pack: TariffPackage): void {
    this.pending = true;
    if (this.isMobile()) {
      this._inAppService.buyPackage(pack.productId)
        .then(error => {
          this.pending = false;
          if (error) {
            if (error.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
              this._noty.error('error.payment.cancelled');
            } else {
              this._noty.error('error.payment.failed');
            }
          }
        });
      return;
    }
    this._userService.buyTariffPackage(this.tariff.id, this.pack.id)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(res => {
        Browser.open({ url: res.url }).then();
      });

  }
}
