import { animate, keyframes, style, transition, trigger } from '@angular/animations';

// -----------------------------------------------------------------------------------------------------
// @ Fade in
// -----------------------------------------------------------------------------------------------------
const iconBounce = trigger('iconBounce', [
  transition(':enter', [
    animate(
      '300ms cubic-bezier(0.25, 1.5, 0.5, 1)',
      keyframes([
        style({ transform: 'translate(-50%, 50%) scale(0)', opacity: 0, offset: 0 }),
        style({ transform: 'translate(-50%, -10%) scale(1.2)', opacity: 1, offset: 0.7 }),
        style({ transform: 'translate(-50%, -50%) scale(1)', opacity: 1, offset: 1 }),
      ])
    ),
  ]),
  transition(':leave', [
    animate(
      '300ms cubic-bezier(0.25, 1.5, 0.5, 1)',
      keyframes([
        style({ transform: 'translate(-50%, -50%) scale(1)', opacity: 1, offset: 0 }),
        style({ transform: 'translate(-50%, -10%) scale(1.2)', opacity: 0.7, offset: 0.3 }),
        style({ transform: 'translate(-50%, 50%) scale(0)', opacity: 0, offset: 1 }),
      ])
    ),
  ]),
]);

export { iconBounce };
