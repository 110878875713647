<div class="flex flex-col w-full p-5 flex-auto overflow-y-auto" [formGroup]="form">
  <div class="w-full flex flex-col flex-auto">
    <app-selectbox [disabled]="true" [placeholder]="'placeholder.voice'" [defaultItem]="form.controls.voice.value" [items]="voices" [search]="false"
                   (itemSelected)="changeVoice($event)"></app-selectbox>
    <app-selectbox [placeholder]="'placeholder.message.tts'" [defaultItem]="form.controls.messageTts.value" [items]="messageTts" [search]="false"
                   (itemSelected)="changeMessageTts($event)" class="mt-5"></app-selectbox>
    <app-select-lang [defaultLang]="form.controls.language.value" (langSelected)="setLang($event)" class="mt-5"></app-select-lang>
    <app-select-lang [placeholder]="'placeholder.interface.language'" [defaultLang]="form.controls.interfaceLanguage.value" (langSelected)="setInterfaceLang($event)"
                     class="mt-5"></app-select-lang>
    <app-selectbox [placeholder]="'placeholder.mic.position'" [items]="micPosition" [defaultItem]="form.controls.micPosition.value" [search]="false"
                   (itemSelected)="changeMicPosition($event)" class="mt-5"></app-selectbox>
    <app-selectbox [placeholder]="'placeholder.edit.mode'" [items]="autoTranslation" [defaultItem]="form.controls.autoTranslation.value" [search]="false"
                   (itemSelected)="changeAutoTranslation($event)" class="mt-5"></app-selectbox>
  </div>
  <div class="pt-5">
    <button class="button w-full" [class.loading]="pending" [disabled]="pending || form.invalid" (click)="savePreferences()">{{ 'button.save' | transloco }}</button>
  </div>
</div>
