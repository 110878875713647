<div class="flex flex-col items-center justify-center">
  <div class="flex items-center mb-1">
    <div class="text-sm capitalize">{{ (lang || 'label.choose') | transloco }}</div>
  </div>
  <div class="stop-record-button" (click)="startRecording()" [class.disabled]="disabled" [class.other]="second">
    @if (disabled) {
      <ion-icon name="mic-off" class="text-5xl"></ion-icon>
    } @else {
      <ion-icon name="mic" class="text-5xl"></ion-icon>
    }
  </div>
</div>
