import {Component, inject, Input, OnInit} from '@angular/core';
import {TranslocoPipe} from '@ngneat/transloco';
import {Room, RoomUser} from '@shared/transport.interface';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {FormArray, FormBuilder, ReactiveFormsModule} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {finalize} from 'rxjs';
import {UserCardComponent} from '@shared/component/ui/user-card/user-card.component';
import {CheckboxComponent} from '@shared/component/ui/checkbox/checkbox.component';
import {RoomService} from '@shared/service/room.service';
import {IonicModule} from '@ionic/angular';
import { ModalController } from '@ionic/angular/standalone';

@UntilDestroy()
@Component({
  selector: 'app-unblock-users',
  standalone: true,
  imports: [
    TranslocoPipe,
    MatIcon,
    MatIconButton,
    UserCardComponent,
    CheckboxComponent,
    ReactiveFormsModule,
    IonicModule
  ],
  templateUrl: './unblock-users.component.html',
  styleUrl: './unblock-users.component.scss'
})
export class UnblockUsersComponent implements OnInit {
  @Input() users: RoomUser[];
  @Input() room: Room;
  private _fb = inject(FormBuilder);
  private _roomService = inject(RoomService);

  private _modalCtrl = inject(ModalController);


  formArray: FormArray;
  pending = false;

  ngOnInit(): void {
    this.formArray = this._fb.array([]);
    this.users.forEach(u => {
      this.formArray.push(this._fb.group({
        "userId": [u.id],
        "checked": [false]
      }));
    });
  }

  unblock(): void {
    const users = this.formArray.value.filter(u => !!u.checked);
    if (!users) {
      return;
    }
    this.pending = true;
    const userIds = users.map(u => u.userId);
    this._roomService.unblockParticipants(this.room, userIds)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(res => {
        this.closeModal(userIds);
      });
  }

  closeModal(userIds?: number[]) {
    this._modalCtrl.dismiss(userIds);
  }

}
