import { Component, inject, OnInit, Signal } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { SearchPipe } from '@shared/pipe/search.pipe';
import { expandCollapse } from '@shared/animation/expand-collapse';
import { addIcons } from 'ionicons';
import { addCircleOutline, closeOutline, removeCircleOutline } from 'ionicons/icons';
import { IonicModule } from '@ionic/angular';
import { ModalController } from '@ionic/angular/standalone';
import { Page, PageContent, RoomUser } from '@shared/transport.interface';
import { environment } from '@env/environment.prod';
import { finalize } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { UserState } from '@shared/store/user/user-state.service';
import { HttpClientService } from '@shared/http-client/http-client.service';
import { LoaderComponent } from '@comp/loader/loader.component';

@UntilDestroy()
@Component({
  selector: 'app-help',
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    NgClass,
    SearchPipe,
    IonicModule,
    LoaderComponent
  ],
  templateUrl: './help.component.html',
  styleUrl: './help.component.scss',
  animations: [ expandCollapse ]
})
export class HelpComponent implements OnInit {
  private _modalCtrl = inject(ModalController);
  private readonly _store = inject(Store);
  private readonly _httpClient = inject(HttpClientService);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  protected loading = false;
  protected page: PageContent;
  protected helpItems: HelpItem[] = [
    // new HelpItem('help.item.0.title', 'help.item.0.desc', 'expanded'),
    // new HelpItem('help.item.1.title', 'help.item.1.desc', 'collapsed'),
  ];


  searchCtrl = new FormControl<string>('');

  constructor() {
    addIcons({ closeOutline, removeCircleOutline, addCircleOutline });
  }

  ngOnInit() {
    this.loading = true;
    const lang = this.me().preferences.interfaceLanguage || this.me().language;
    this._httpClient.doGetUnauthorized<Page>(environment.contentUrl + '/pages/main-page', { paramLang: lang })
      .pipe(
        finalize(() => this.loading = false),
        untilDestroyed(this)).subscribe(res => {
      this.page = res.content.find(page => page.uid === 'faq');
      this.page.items.forEach((item, index) => {
        this.helpItems.push(new HelpItem(item.titles[ 0 ]?.text, item.texts[ 0 ]?.text, index === 0 ? 'expanded' : 'collapsed'));
      });
    });
  }

  closePage() {
    this._modalCtrl.dismiss();
  }
}

export class HelpItem {
  private readonly _title: string;
  private readonly _description: string;
  private _state: 'collapsed' | 'expanded';

  constructor(title: string, description: string, state: 'collapsed' | 'expanded') {
    this._title = title;
    this._description = description;
    this._state = state;
  }

  get title(): string {
    return this._title;
  }

  get description(): string {
    return this._description;
  }

  get state(): 'collapsed' | 'expanded' {
    return this._state;
  }

  set state(value: 'collapsed' | 'expanded') {
    this._state = value;
  }
}
