import { Component, inject, NgZone, OnInit } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { appComponentRef } from '@shared/app-component';
import { Cookies, RoomAction, RoomUser } from '@shared/transport.interface';
import { LangService } from '@shared/service/lang.service';
import { TranslocoService } from '@ngneat/transloco';
import { RxStompService } from '@shared/service/rx-stomp/rx-stomp.service';
import { BehaviorSubject, filter, map, Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { UserState } from '@shared/store/user/user-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SaveLanguagesAction } from '@shared/store/languages/languages.actions';
import { RoomService } from '@shared/service/room.service';
import { SaveUserAction } from '@shared/store/user/user.actions';
import { NavMenuComponent } from '@shared/component/nav-menu/nav-menu.component';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Pages } from '@app/pages';
import { NotyService } from '@service/noty.service';
import { DialogService } from '@service/dialog.service';
import { AuthService } from '@service/auth/auth.service';
import { StorageService } from '@service/storage.service';
import { UserService } from '@service/user.service';
import 'cordova-plugin-purchase';
import { InappPurchasesService } from '@service/inapp-purchases.service';
import { AppVersionService } from '@service/app-version.service';
import { Capacitor } from '@capacitor/core';

@UntilDestroy()
@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: 'app.component.html',
  styleUrls: [ 'app.component.scss' ],
  imports: [
    IonicModule,
    NavMenuComponent
  ]
})
export class AppComponent implements OnInit {
  private readonly _langService = inject(LangService);
  private readonly _translate = inject(TranslocoService);
  private readonly _ws = inject(RxStompService);
  private readonly _store = inject(Store);
  private readonly _roomService = inject(RoomService);
  private readonly _platform = inject(Platform);
  private readonly _zone = inject(NgZone);
  private readonly _router = inject(Router);
  private readonly _noty = inject(NotyService);
  private readonly _dialogService = inject(DialogService);
  private readonly _authService = inject(AuthService);
  private readonly _storageService = inject(StorageService);
  private readonly _userService = inject(UserService);
  private readonly _inappService = inject(InappPurchasesService);
  private readonly _versionService = inject(AppVersionService);

  private user$: Observable<RoomUser> = this._store.select(UserState.getUser);
  private _maintenanceEvent: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private mySubs: Subscription;

  private user: RoomUser;

  constructor() {
    this._platform.ready().then(() => {
      this.initializeApp();
      this.statusBarConfig();
      this.checkForUpdates();
    });
  }

  ngOnInit(): void {
    appComponentRef(this);
    this.languageConfig();
    this.afterWSConnected();
    this.aiLangs();
    this._platform.ready().then(() => {
      if (Capacitor.isNativePlatform()) {
        this.initInAppPurchases();
      }
    });
  }

  private aiLangs() {
    this._langService.langsFroAI()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this._store.dispatch(new SaveLanguagesAction(res));
      });
  }

  private afterWSConnected() {
    this._ws.connected$
      .subscribe(() => {
        this.user$.subscribe(res => {
          this.user = res;
          if (this.user.preferences?.interfaceLanguage) {
            this._translate.setActiveLang(this.user.preferences?.interfaceLanguage);
          }
          this.subscribeToMyEvents(res);
        });
      });
  }

  private languageConfig() {
    this._storageService.get(Cookies.UI_LANGUAGE).then((lang: string) => {
      if (!lang) {
        lang = this._langService.getBrowserLang();
        this._storageService.set(Cookies.UI_LANGUAGE, lang);
      }
      this._translate.setActiveLang(lang);
      this._langService.checkForRtl(lang);
    });
  }

  private statusBarConfig() {

  }


  invalidSessionEvent() {
    console.log('[invalidSessionEvent]');
    this._storageService.clear();
    this._noty.success('message.session.expired');
    this._dialogService.closeAll();
    this._router.navigate([ '/', Pages.LOGIN ]);
    this._authService.loggedIn.set(false);
  }

  public maintenanceEvent(): void {
    this._maintenanceEvent.next(true);
  }

  private subscribeToMyEvents(user: RoomUser) {
    if (this.mySubs) {
      this.mySubs.unsubscribe();
    }
    this.mySubs = this._ws.subscribe(`/topic/user/${ user.id }/events`)
      .pipe(
        untilDestroyed(this),
        map(res => JSON.parse(res.body)))
      .subscribe(res => {
        this._roomService.sendMyEvent(res);
        if (res.action === RoomAction.UPDATE_TOKEN) {
          if (res.accessToken) {
            this._storageService.saveAccessToken(res.accessToken);
          }
        }
        if (res.action === RoomAction.TIME_LEFT) {
          this.user.duration = res.duration;
          if (this.user.duration <= 0) {
            this.user.duration = 0;
          }
          this._store.dispatch(new SaveUserAction(this.user));
        }
      });
  }

  public maintenanceObs(): Observable<boolean> {
    return this._maintenanceEvent.asObservable().pipe(filter(val => !!val));
  }

  private initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this._zone.run(() => {
        const slug = event.url.split("finexword.com").pop();
        console.log('Redirecting to:', slug);
        this._router.navigateByUrl(slug);
      });
    });
    this._platform.backButton.subscribeWithPriority(999999, () => {
      // do on back button click
    });
    this.maintenanceObs()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this._router.navigate([ '/', Pages.MAINTENANCE ]);
      });
  }

  private initInAppPurchases() {
    this._userService.buyPackagesInfo()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this._inappService.initStore(CdvPurchase.store, res);
      });
  }

  private checkForUpdates() {
    this._versionService.isUpdateAvailable().then((isUpdate) => {
      if (isUpdate) {
        this._versionService.showUpdateAlert().then(action => {

        });
      }
    })
  }
}
