import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AudioService } from '@shared/service/audio.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-audio-visualizer',
  templateUrl: './audio-visualizer.component.html',
  styleUrls: [ './audio-visualizer.component.scss' ],
  standalone: true
})
export class AudioVisualizerComponent implements OnInit, OnDestroy {
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef<HTMLCanvasElement>;
  private readonly _audioService = inject(AudioService);
  private readonly _platform = inject(Platform);

  ngOnInit(): void {
    this._audioService.audioMotion.start(this.canvasRef.nativeElement);
  }

  ngOnDestroy(): void {
    if (this._audioService.audioMotion) {
      try {
        this._audioService.audioMotion.stop();
        if (this._platform.is('desktop') || this._platform.is('mobileweb')) {
          if (this._audioService.audioMotion.canvas) {
            this._audioService.audioMotion.canvas.parentElement.removeChild(this._audioService.audioMotion.canvas);
            let canvases = document.body.getElementsByTagName('canvas');
            const len = canvases.length;
            for (let i = 0; i < len; i++) {
              canvases.item(i).parentElement.removeChild(canvases.item(i));
            }
          }
          this._audioService.audioMotion.destroy();
        }
      } catch (e) {
        //
      }
    }
  }

}
