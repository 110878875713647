import { Component, inject, OnInit, Signal } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { NgTemplateOutlet } from '@angular/common';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { DialogService } from '@service/dialog.service';
import { AudioService } from '@service/audio.service';
import { NotyService } from '@service/noty.service';
import { RoomService } from '@service/room.service';
import { RoomMode, RoomUser, UserRole } from '../../transport.interface';
import { UserState } from '../../store/user/user-state.service';
import { Pages } from '@app/pages';
import { InitialsAvatarComponent } from '../ui/initials-avatar/initials-avatar.component';
import { addIcons } from 'ionicons';
import { IonicModule, Platform } from '@ionic/angular';
import { chatbubbleOutline, chatbubblesOutline, personAddOutline, qrCodeOutline } from 'ionicons/icons';
import { StorageService } from '@service/storage.service';

@UntilDestroy()
@Component({
  selector: 'app-small-nav-menu',
  standalone: true,
  imports: [
    TranslocoPipe,
    NgTemplateOutlet,
    InitialsAvatarComponent,
    IonicModule,
  ],
  templateUrl: './small-nav-menu.component.html',
  styleUrl: './small-nav-menu.component.scss'
})
export class SmallNavMenuComponent implements OnInit {
  private readonly _platform = inject(Platform);
  private readonly _router = inject(Router);
  private readonly _dialogService = inject(DialogService);
  private readonly _store = inject(Store);
  private readonly _audioService = inject(AudioService);
  private readonly _notyService = inject(NotyService);
  private readonly _storageService = inject(StorageService);
  protected readonly _roomService = inject(RoomService);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  protected readonly UserRole = UserRole;
  protected logged = false;
  protected readonly Pages = Pages;
  protected pending = {};
  protected fullscreen = false;

  constructor() {
    addIcons({ qrCodeOutline, chatbubbleOutline, chatbubblesOutline, personAddOutline });
    this._storageService.logged().then(logged => {
      this.logged = logged;
    });
  }

  ngOnInit(): void {
    console.log();
  }

  navigate(page?: Pages): void {
    if (this.me().role === UserRole.TEMP_USER || !this.me()?.id) {
      this._notyService.success('message.register');
      return;
    }
    this._router.navigate([ '/', page || '' ]);
    this._dialogService.closeAll();
  }

  openRoomsHistory(): void {
    if (this.me().role === UserRole.TEMP_USER || !this.me()?.id) {
      this._notyService.success('message.register');
      return;
    }
    this._dialogService.closeAll();
    if (!this.logged) {
      this._router.navigate([ '/', Pages.LOGIN ]);
      return;
    }
    this._dialogService.roomsHistory();
  }

  openProfile(): void {
    this._dialogService.closeAll();
    if (!this.logged) {
      this._router.navigate([ '/', Pages.LOGIN ]);
      return;
    }
    this._dialogService.profile();
  }

  joinRoomByQR(): void {
    if (this.me().role === UserRole.TEMP_USER || !this.me()?.id) {
      this._notyService.success('message.register');
      return;
    }
    if (this._platform.is('desktop')) {
      this._dialogService.joinRoom();
      return;
    }
    this._dialogService.qrCodeScanner().subscribe(code => {
      if (code) {
        this._router.navigate([ '/', Pages.JOIN_ROOM, code ]);
      }
    });
  }

  showInviteQRCode(): void {
    if (this.me().role === UserRole.TEMP_USER || !this.me()?.id) {
      this._notyService.success('message.register');
      return;
    }
    if (this._roomService.isSingleMode) {
      this._roomService.joinMyRoom(RoomMode.MULTI)
        .pipe(untilDestroyed(this), finalize(() => this.pending = false))
        .subscribe({
          next: () => {
            this._storageService.clearRoomId();
            this._router.navigate([ '/', Pages.CHAT ], { queryParams: { mode: 'conference', invite: true } });
          }
        });
      return;
    }
    this._dialogService.qrCode(this._roomService.room.roomId);
  }

  startChat(mode: RoomMode): void {
    this._dialogService.closeAll();
    if (this.me().role === UserRole.TEMP_USER || !this.me()?.id) {
      this._notyService.success('message.register');
      return;
    }
    this._audioService.initAudioForIos();
    this.pending[ mode ] = true;
    this._roomService.joinMyRoom(mode)
      .pipe(untilDestroyed(this), finalize(() => this.pending[ mode ] = false))
      .subscribe({
        next: () => {
          this._storageService.clearRoomId();
          let m = mode === RoomMode.SINGLE ? 'single' : 'conference';
          this._router.navigate([ '/', Pages.CHAT ], { queryParams: { mode: m } });
        }, error: err => {
          this._notyService.error(err.message);
        }
      });
  }

  protected readonly RoomMode = RoomMode;

}
