import {inject, Injectable, signal} from '@angular/core';
import {Capacitor} from '@capacitor/core';
import {AppUpdate, AppUpdateAvailability} from '@capawesome/capacitor-app-update';
import {AlertController, Platform} from '@ionic/angular';
import {TranslocoService} from '@ngneat/transloco';
import {Topics} from '@shared/topics';
import {WSReqActions} from '@shared/transport.interface';
import {RxStompService} from '@service/rx-stomp/rx-stomp.service';
import {RxStompState} from '@stomp/rx-stomp';
import {AppUpdateResultCode} from '@capawesome/capacitor-app-update/dist/esm/definitions';

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {
  private readonly _platform = inject(Platform);
  private readonly _alertController = inject(AlertController);
  private readonly _translate = inject(TranslocoService);
  private readonly _ws = inject(RxStompService);

  private _currentVersion = signal<string>('');
  private _version = signal<string>('');
  private _availableVersion = signal<string>('');
  private _immediateUpdate = signal<boolean>(false);
  private _flexibleUpdate = signal<boolean>(false);

  constructor() {
  }

  getCurrentVersion() {
    return this._currentVersion() + (this._version() ? ` (${this._version()})` : '');
  }

  async isUpdateAvailable() {
    return this._platform.ready().then(() => {
      console.info('Platform ready!');
      if (Capacitor.isNativePlatform()) {
        console.info('Platform name:', Capacitor.getPlatform());
        return AppUpdate.getAppUpdateInfo().then(res => {
          if (res.currentVersionCode) {
            this._version.set(res.currentVersionCode);
          } else {
            this._version.set(res.currentVersionName);
          }
          if (Capacitor.getPlatform() === 'android') {
            this._availableVersion.set(res.availableVersionCode);
          } else {
            this._availableVersion.set(res.availableVersionName);
          }
          this._currentVersion.set(res.currentVersionName);
          this._ws.connected$.subscribe((state) => {
            if (state !== RxStompState.OPEN) {
              return;
            }
            this._ws.sendToTopic(Topics.LOGS, {
              type: WSReqActions.LOG,
              currentVersionCode: res.currentVersionCode,
              currentVersionName: res.currentVersionName,
              availableVersionCode: res.availableVersionCode,
              availableVersionName: res.availableVersionName,
              updateAvailability: res.updateAvailability,
              immediateUpdateAllowed: res.immediateUpdateAllowed,
              flexibleUpdateAllowed: res.flexibleUpdateAllowed,
            }, {});
          });
          this._immediateUpdate.set(res.immediateUpdateAllowed);
          this._flexibleUpdate.set(res.flexibleUpdateAllowed);
          return Promise.resolve(res.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE);
        }).catch(err => {
          console.info(err);
          this._ws.connected$.subscribe((state) => {
            if (state !== RxStompState.OPEN) {
              return;
            }
            this._ws.sendToTopic(Topics.LOGS, {
              type: WSReqActions.LOG,
              error: JSON.stringify(err),
            }, {});
          });
          return Promise.resolve(false);
        });
      }
      return Promise.resolve(false);
    });
  }

  async showUpdateAlert() {
    const buttons = [
      {
        text: this._translate.translateObject('button.update.later'),
        role: 'cancel',
      },
      {
        text: this._translate.translateObject('button.update.now'),
        role: 'confirm',
      },
    ];
    const alert = await this._alertController.create({
      header: this._translate.translateObject('title.update.app'),
      message: this._translate.translateObject('text.update.app'),
      buttons,
    });
    alert.onDidDismiss().then(res => {
      if (res.role === 'confirm') {
        if (this._immediateUpdate()) {
          AppUpdate.performImmediateUpdate();
        } else if (this._flexibleUpdate()) {
          AppUpdate.startFlexibleUpdate().then(res => {
            if (res.code === AppUpdateResultCode.OK) {
              AppUpdate.completeFlexibleUpdate();
            }
          });
        } else {
          AppUpdate.openAppStore();
        }
      }
    });
    await alert.present();
  }
}
