import { Component, inject, Input } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoomService } from '@shared/service/room.service';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import { NgxOtpInputComponent, NgxOtpInputComponentOptions } from 'ngx-otp-input';
import { finalize } from 'rxjs';
import { Router } from '@angular/router';
import { NotyService } from '@service/noty.service';
import { Pages } from '@app/pages';
import { FuseAlertComponent } from '@comp/ui/alert';
import { DialogService } from '@service/dialog.service';

@UntilDestroy()
@Component({
  selector: 'app-join-room-by-code',
  standalone: true,
  imports: [
    MatIconButton,
    TranslocoPipe,
    IonicModule,
    NgxOtpInputComponent,
    FuseAlertComponent
  ],
  templateUrl: './join-room-by-code.component.html',
  styleUrl: './join-room-by-code.component.scss'
})
export class JoinRoomByCodeComponent {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  protected pending = false;
  protected valid = false;
  protected notFound = false;
  protected roomId = '';
  protected otpOptions: NgxOtpInputComponentOptions = {
    otpLength: 6,
    inputMode: 'text',
    autoFocus: true,
    regexp: /^[0-9a-zA-Z]+$/
  };

  private readonly _roomService = inject(RoomService);
  private readonly _router = inject(Router);
  private readonly _dialogs = inject(DialogService);

  constructor() {
    addIcons({ closeOutline });
  }

  joinRoom(): void {
    this.notFound = false;
    if (!this.valid) {
      return;
    }
    this.pending = true;
    this._roomService.searchRoom(this.roomId)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe((result) => {
        if (result.exists) {
          this._router.navigate([ '/', Pages.JOIN_ROOM, this.roomId ]);
          this._dialogs.closeAll();
        } else {
          this.notFound = true;
        }
      });
  }

  enableSearchButton() {
    this.valid = true;
  }

  changeRoomId($event: string[]) {
    this.notFound = false;
    this.roomId = $event.join('');
    if (!this.roomId || this.roomId.length < 6) {
      this.valid = false;
    }
  }
}
