<ion-header>
  <ion-toolbar>
    <div class="sm:flex sm:justify-center sm:w-[130px]">
      <ion-buttons slot="secondary">
        <ion-menu-button auto-hide="false"></ion-menu-button>
      </ion-buttons>
    </div>
    <ion-title>
      <div class="flex items-center justify-center flex-auto line-clamp-1 w-full sm:ml-[67px]">
        @if (_roomService.mode() === RoomMode.MULTI) {
          <div class="cursor-pointer w-full text-center flex flex-col items-center justify-center" (click)="showRoomInfo()">
            @if (_roomService.hostRoom) {
              <div class="title text-2xl text-center font-bold truncate">{{ _roomService.hostRoom.name }}</div>
            } @else {
              <div class="title text-2xl text-center font-bold truncate">{{ _roomService.hostUser?.name }}</div>
            }
            @if (!nowSpeaking?.length) {
              <div class="text-sm h-[18px] overflow-hidden normal-text">{{ 'label.participants' |  transloco: { count: _roomService.users?.length } }}</div>
            } @else {
              <div class="text-sm flex items-center text-center h-[18px] overflow-hidden normal-text">
                <ion-icon name="mic" class="text-sm"></ion-icon>&nbsp;{{ speakingText }}
              </div>
            }
          </div>
        } @else {
          <div class="flex title text-2xl items-center text-center w-full">
            <div class="title flex-1 text-3xl flex items-center justify-end pr-4 w-[39%] line-clamp-1" [class.text-gray-400]="!me()?.secondLanguage">
                <span id="tour-step-1" (click)="changeLang(null,true)" class="cursor-pointer line-clamp-1 text-ellipsis text-right">{{
                    (me()?.secondLanguage || 'label.choose') | transloco
                  }}</span>
            </div>
            <div class="w-[24px] leading-[0]">
              @if (!swapLanguageLoader) {
                <ion-icon name="swap-horizontal" class="cursor-pointer" (click)="swapLanguages()"></ion-icon>
              } @else {
                <ion-icon name="sync-outline" class="spin-animation"></ion-icon>
              }
            </div>
            <div class="title flex-1 text-3xl flex items-center justify-start pl-4  w-[39%] line-clamp-1" [class.text-gray-400]="!me()?.language">
              <span (click)="changeLang(null, false)" class="cursor-pointer line-clamp-1 text-ellipsis text-left">
                {{ (me()?.language || 'label.choose') | transloco }}
              </span>
            </div>
          </div>
        }
      </div>
    </ion-title>
    <ion-buttons slot="primary">
      @if (me().role !== UserRole.TEMP_USER && _roomService.mode() === RoomMode.MULTI && _roomService.creator) {
        <ion-button (click)="showInviteQRCode()">
          <ion-icon slot="icon-only" name="person-add"></ion-icon>
        </ion-button>
        <ion-button [matMenuTriggerFor]="menu">
          <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
        </ion-button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="showRoomInfo()">
            <ion-icon name="information-circle" class="text-2xl"></ion-icon>
            <span>{{ 'button.chat.info' | transloco }}</span>
          </button>
          <button mat-menu-item (click)="createSummary(ChatResultType.SUMMARY)">
            <ion-icon src="/assets/icons/awesome/icon-file-lines.svg" class="text-2xl"></ion-icon>
            <span>{{ 'title.data.type.1' | transloco }}</span>
          </button>
          <button mat-menu-item (click)="createSummary(ChatResultType.PROTOCOL)">
            <ion-icon src="/assets/icons/awesome/icon-file-invoice.svg" class="text-2xl"></ion-icon>
            <span>{{ 'title.data.type.2' | transloco }}</span>
          </button>
          <button mat-menu-item (click)="createSummary(ChatResultType.TRANSCRIPT)">
            <ion-icon src="/assets/icons/awesome/icon-file-contract.svg" class="text-2xl"></ion-icon>
            <span>{{ 'title.data.type.3' | transloco }}</span>
          </button>
          <button mat-menu-item (click)="changeLang(null, false, true)">
            <ion-icon name="language-outline" class="text-2xl"></ion-icon>
            <span>{{ 'button.change.language' | transloco }}</span>
          </button>
          <button mat-menu-item (click)="exitRoom()">
            <ion-icon src="/assets/icons/awesome/icon-file.svg" class="text-2xl"></ion-icon>
            <span>{{ 'button.new.conversation' | transloco }}</span>
          </button>
          <!--          @if (me().ref) {-->
          <!--            <button mat-menu-item (click)="shareApp()">-->
          <!--              <ion-icon name="share-social-outline" class="text-2xl"></ion-icon>-->
          <!--              <span>{{ 'button.share.app' | transloco }}</span>-->
          <!--            </button>-->
          <!--          }-->
          <!--          <button mat-menu-item (click)="toggleFullscreen()">-->
          <!--            <ion-icon name="scan" class="text-2xl"></ion-icon>-->
          <!--            <span>{{ 'button.fullscreen' | transloco }}</span>-->
          <!--          </button>-->
        </mat-menu>
      } @else if (_roomService.mode() === RoomMode.SINGLE) {
        <ion-button (click)="shareChat()">
          <ion-icon slot="icon-only" name="person-add"></ion-icon>
        </ion-button>
        <ion-button [matMenuTriggerFor]="menu">
          <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
        </ion-button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="showRoomInfo()">
            <ion-icon name="information-circle" class="text-2xl"></ion-icon>
            <span>{{ 'button.chat.info' | transloco }}</span>
          </button>
          <button mat-menu-item (click)="createSummary(ChatResultType.SUMMARY)">
            <ion-icon src="/assets/icons/awesome/icon-file-lines.svg" class="text-2xl"></ion-icon>
            <span>{{ 'title.data.type.1' | transloco }}</span>
          </button>
          <button mat-menu-item (click)="createSummary(ChatResultType.PROTOCOL)">
            <ion-icon src="/assets/icons/awesome/icon-file-invoice.svg" class="text-2xl"></ion-icon>
            <span>{{ 'title.data.type.2' | transloco }}</span>
          </button>
          <button mat-menu-item (click)="createSummary(ChatResultType.TRANSCRIPT)">
            <ion-icon src="/assets/icons/awesome/icon-file-contract.svg" class="text-2xl"></ion-icon>
            <span>{{ 'title.data.type.3' | transloco }}</span>
          </button>

          <button mat-menu-item (click)="showMessages()">
            <ion-icon src="/assets/icons/awesome/icon-history.svg" class="text-2xl"></ion-icon>
            <span>{{ 'button.show.messages' | transloco }}</span>
          </button>

          <button mat-menu-item (click)="exitRoom()">
            <ion-icon src="/assets/icons/awesome/icon-file.svg" class="text-2xl"></ion-icon>
            <span>{{ 'button.new.conversation' | transloco }}</span>
          </button>
          <!--          @if (me().ref) {-->
          <!--            <button mat-menu-item (click)="shareApp()">-->
          <!--              <ion-icon name="share-social-outline" class="text-2xl"></ion-icon>-->
          <!--              <span>{{ 'button.share.app' | transloco }}</span>-->
          <!--            </button>-->
          <!--          }-->
          <!--          <button mat-menu-item (click)="toggleFullscreen()">-->
          <!--            <ion-icon name="scan" class="text-2xl"></ion-icon>-->
          <!--            <span>{{ 'button.fullscreen' | transloco }}</span>-->
          <!--          </button>-->
        </mat-menu>

      } @else if (_roomService.mode() === RoomMode.MULTI) {
        <ion-button (click)="changeLang(null, false, true)">
          <ion-icon slot="icon-only" name="language-outline"></ion-icon>
        </ion-button>
        <ion-button (click)="leaveRoom()">
          <ion-icon slot="icon-only" name="log-out-outline"></ion-icon>
        </ion-button>
      }
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<!-- Main screen -->
<div class="ai-chat-container flex-auto flex flex-col w-full min-w-0 overflow-hidden relative">
  @if (_roomService.waitingUsers?.length) {
    <div class="absolute justify-center bg-white w-full z-1 top-0 flex items-center border-b-gray-300 border-b p-2">
      <div class="cursor-pointer" (click)="acceptUsersDialog()">{{ 'text.waiting.users' | transloco: { count: _roomService.waitingUsers.length } }}</div>
    </div>
  }
  <div class="flex w-full h-full">
    <div class="max-sm:hidden">
      <app-small-nav-menu></app-small-nav-menu>
    </div>
    <div class="w-full flex-auto flex justify-center overflow-hidden"
         [ngClass]="{'single-mode': _roomService.mode() === RoomMode.SINGLE, 'multi-mode': _roomService.mode() === RoomMode.MULTI}">
      <div class="flex flex-col min-[600px]:w-3/5 max-[600px]:w-full relative sm:w-full max-sm:w-full">
        <div class="ai-chat flex flex-auto flex-col overflow-x-hidden chat-container"
             [ngClass]="{'overflow-hidden': _roomService.mode() === RoomMode.SINGLE}">
          <div class="flex flex-col flex-auto pt-4 pb-4 chat-container-bg shrink max-[600px]:pl-4 max-[600px]:pr-4 px-4"
               [class.p-4]="_roomService.mode() === RoomMode.MULTI"
               [class.overflow-hidden]="_roomService.mode() === RoomMode.SINGLE">
            <div class="flex flex-col flex-auto relative" [class.overflow-hidden]="_roomService.mode() === RoomMode.SINGLE">
              @if (loading) {
                <app-loader></app-loader>
              } @else {
                @if (_roomService.mode() === RoomMode.MULTI) {
                  <ng-container *ngTemplateOutlet="multiModeChatWindow"></ng-container>
                } @else {
                  <ng-container *ngTemplateOutlet="singleModeChatWindow"></ng-container>
                }
              }
            </div>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
      </div>
    </div>
  </div>
</div>
<!-- Main screen end -->
@if (!skipTour && _roomService.mode() === RoomMode.SINGLE && !loading) {
  <app-tour (completeTour)="skipTour = true"></app-tour>
}
<!-- Templates -->
<ng-template let-lang let-creator="creator" let-muted="muted" let-avatarId="avatarId" #avatarLetters>
  <app-initials-avatar [avatarId]="avatarId" [user]="{name: lang}" [muted]="muted" [creator]="creator" [width]="30" [textSize]="'text-xs'"></app-initials-avatar>
</ng-template>

<ng-template #userJoinedMessage let-message>
  <div class="w-full flex justify-between user-joined-message-bg py-1 px-1.5 text-sm mb-4 items-center">
    <div class="flex items-center">
      <div>
        <app-initials-avatar [avatarId]="message.avatarId" [bgColor]="'chat-avatar-bg-color'" [user]="{name: message.name}" [width]="20"
                             [textSize]="'text-[8px]'"></app-initials-avatar>
      </div>
      <div class="ltr:ml-2 rtl:mr-2" [innerHTML]="'text.user.joined.chat' | transloco: {user: message.name}"></div>
    </div>
    <div class="time-color">{{ (message.time * 1000) | date: 'HH:mm' }}</div>
  </div>
</ng-template>

<ng-template #userLeftMessage let-message>
  <div class="w-full flex justify-between user-joined-message-bg py-1 px-1.5 text-sm mb-4 items-center">
    <div class="flex items-center">
      <div>
        <app-initials-avatar [avatarId]="message.avatarId" [bgColor]="'chat-avatar-bg-color'" [user]="{name: message.name}" [width]="20"
                             [textSize]="'text-[8px]'"></app-initials-avatar>
      </div>
      <div class="ltr:ml-2 rtl:mr-2" [innerHTML]="'text.user.left.chat' | transloco: {user: message.name}"></div>
    </div>
    <div class="time-color">{{ (message.time * 1000) | date: 'HH:mm' }}</div>
  </div>
</ng-template>

<ng-template #summaryMessage let-message>
  <div class="w-full flex flex-col user-joined-message-bg p-4 text-sm mb-4">
    <div class="flex">
      <div>
        <ion-icon src="/assets/icons/awesome/icon-file-lines.svg" class="text-3xl"></ion-icon>
      </div>
      <div class="ltr:ml-2.5 rtl:mr-2.5">
        <div class="text-base">{{ 'title.generated.summary' | transloco }}</div>
        <div class="text-base font-bold">{{ message.summaryData?.title && message.summaryData?.title?.[me().language] }}</div>
      </div>
    </div>
    <div class="mt-2.5 flex justify-between items-center">
      <div class="time-color">{{ (message.time * 1000) | date: 'HH:mm' }}</div>
      @if (!message.archived) {
        <div class="title-1 text-2xl font-bold active cursor-pointer" [class.loading]="summaryLoad[ message.summaryData.shareCode ]"
             (click)="showSummary(message.summaryData.shareCode)">
          @if (!summaryLoad[message.summaryData.shareCode]) {
            {{ 'link.open' | transloco }}
          }
        </div>
      } @else {
        <div class="title-1 text-2xl font-bold text-gray-400">
          {{ 'link.archived' | transloco }}
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #protocolMessage let-message>
  <div class="w-full flex flex-col user-joined-message-bg p-4 text-sm mb-4">
    <div class="flex">
      <div>
        <ion-icon src="/assets/icons/awesome/icon-file-invoice.svg" class="text-3xl"></ion-icon>
      </div>
      <div class="ltr:ml-2.5 rtl:mr-2.5">
        <div class="text-base">{{ 'title.generated.protocol' | transloco }}</div>
        <div class="text-base font-bold">{{ message.protocolData?.title && message.protocolData?.title?.[me().language] }}</div>
      </div>
    </div>
    <div class="mt-2.5 flex justify-between items-center">
      <div class="time-color">{{ (message.time * 1000) | date: 'HH:mm' }}</div>
      @if (!message.archived) {
        <div class="title-1 text-2xl font-bold active cursor-pointer" [class.loading]="protocolLoad[ message.protocolData.shareCode ]"
             (click)="showProtocol(message.protocolData.shareCode)">
          @if (!protocolLoad[message.protocolData.shareCode]) {
            {{ 'link.open' | transloco }}
          }
        </div>
      } @else {
        <div class="title-1 text-2xl font-bold text-gray-400">
          {{ 'link.archived' | transloco }}
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #transcriptMessage let-message>
  <div class="w-full flex flex-col user-joined-message-bg p-4 text-sm mb-4">
    <div class="flex">
      <div>
        <ion-icon src="/assets/icons/awesome/icon-file-contract.svg" class="text-3xl"></ion-icon>
      </div>
      <div class="ltr:ml-2.5 rtl:mr-2.5">
        <div class="text-base">{{ 'title.generated.transcript' | transloco }}</div>
        <div class="text-base font-bold">{{ message.transcriptData?.title && message.transcriptData?.title?.[me().language] }}</div>
      </div>
    </div>
    <div class="mt-2.5 flex justify-between items-center">
      <div class="time-color">{{ (message.time * 1000) | date: 'HH:mm' }}</div>
      @if (!message.archived) {
        <div class="title-1 text-2xl font-bold active cursor-pointer" [class.loading]="transcriptLoad[ message.transcriptData.shareCode ]"
             (click)="showTranscript(message.transcriptData.shareCode)">
          @if (!transcriptLoad[message.transcriptData.shareCode]) {
            {{ 'link.open' | transloco }}
          }
        </div>
      } @else {
        <div class="title-1 text-2xl font-bold text-gray-400">
          {{ 'link.archived' | transloco }}
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #chatMessage let-t>
  <div class="flex relative items-start max-w-full mb-4" [class.inactive-msg]="copyTime && copyTime !== t.time" [ngClass]="{'justify-end': t.userId === me().id}">
    @if (t.userId !== me().id) {
      <div class="lang ltr:mr-2 rtl:ml-2">
        <ng-container *ngTemplateOutlet="avatarLetters; context: {$implicit: t.name, avatarId: t.avatarId}"></ng-container>
      </div>
    }
    <div
      class="flex flex-col flex-auto max-w-2/3 overflow-hidden"
      [ngClass]="{'items-end': t.userId === me().id, 'items-start': t.userId !== me().id}">
      @if (t.userId !== me().id) {
        <div class="text-xl title font-semibold truncate w-full">{{ t.name }}</div>
      }
      <div
        class="relative min-w-44 px-3 pb-2 pt-2.5 max-w-[70%]"
        [ngClass]="{'chat-my-msg-bg text-white': t.userId === me().id, 'chat-msg-bg': t.userId !== me().id}">
        @if (t.photo) {
          <div class="thumb-photo-container mb-2">
            <img [src]="environment.serverUrl + '/v1/photos/' + t.photo" alt="" class="photo" (click)="showImage(environment.serverUrl + '/v1/photos/' + t.photo)"/>
          </div>
        }
        @if (t.userId === me().id || showOriginalTexts[t.time]) {
          <div class="leading-5 wrap text-base chat-msg" [innerHTML]="t.originalText || ('not.translated' | transloco)"></div>
        } @else {
          @if (me().language === t.originalLang) {
            <div class="leading-5 wrap text-base chat-msg" [innerHTML]="t.originalText"></div>
          } @else {
            <div class="leading-5 wrap text-base chat-msg" [ngClass]="{'opacity-50 italic': !(t.szdMessages && t.szdMessages[t.users[me().id]] || t.szdMessages[me().language])}"
                 [innerHTML]="t.szdMessages && t.szdMessages[t.users[me().id]] || t.szdMessages[me().language] || ('message.not.translated' | transloco)"></div>
          }
        }

        <div class="flex justify-between items-center mt-2.5">
          <div class="text-xs chat-msg-date-color">
            @if (showOriginalTexts[t.time]) {
              {{ t.originalLang | transloco }}
            } @else {
              {{ (t.time * 1000) | date: 'HH:mm' }}
            }
          </div>
          <div class="flex items-center relative">
            @if (copyTime && copyTime === t.time) {
              <div
                class="bg-black text-white py-1.5 px-1 text-center text-xs absolute max-w-32 -translate-y-full -translate-x-1/2 left-0 right-0 m-auto">{{ 'text.copied' | transloco }}
              </div>
            }

            @if (t.userId !== me().id) {
              <ion-icon name="language-outline" (click)="showOriginalTextMultiMode(t)" [class.active]="showOriginalTexts[ t.time ]"
                        class="text-3xl rtl:ml-2 cursor-pointer"></ion-icon>
            }
            <ion-icon name="copy-outline" class="text-3xl rtl:mr-2 ltr:ml-2 cursor-pointer" (click)="copyText(t.originalLang, t.originalText, t.time)"
                      [class.active]="copyTime && copyTime === t.time"></ion-icon>

            @if (t.originalText.length < MAX_MESSAGE_LENGTH) {
              <ion-icon
                name="{{me().language === readLanguage && t.time === lastTime && audioPlayerStatus() === AudioPlayerStatus.PLAYING ? 'stop-circle-outline' : 'volume-high-outline'}}"
                (click)="readText(t.time, me().language === t.originalLang ? t.originalLang : me().language, t.originalLang)"
                class="text-3xl ltr:ml-2 cursor-pointer"
                [class.active]="me().language === readLanguage && t.time === lastTime && audioPlayerStatus() === AudioPlayerStatus.PLAYING"></ion-icon>
            } @else {
              <ion-icon name="volume-mute-outline"
                        class="cursor-pointer text-3xl ltr:ml-2 opacity-50"></ion-icon>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #actionButtons>
  <div class="ai-recorder w-full flex justify-center border-t border-t-gray-300"
       [class.single-mode-action-panel]="_roomService.mode() === RoomMode.SINGLE"
       [class.multi-mode-action-panel]="_roomService.mode() === RoomMode.MULTI">
    <div class="flex w-full justify-end items-center">
      <div class="record-button">
        @if (_roomService.mode() === RoomMode.SINGLE) {
          <div class="w-full flex mt-2 mb-4" [class.flex-row-reverse]="me()?.preferences?.micPosition === MicPosition.LEFT">
            @if (chatState().top.editMode || chatState().bottom.editMode) {
              @if (chatState().bottom.editMode && landscape()) {
                <div class="w-1/2">&nbsp;</div>
              }
              <div class="flex justify-center items-center" [class.flex-row-reverse]="me()?.preferences?.micPosition === MicPosition.LEFT" [ngClass]="{'w-full p-5': !landscape(), 'w-1/2': landscape()}">
                <ng-container *ngTemplateOutlet="sendMessageButton; context: {$implicit: chatState().bottom.editMode ? chatState().bottom : chatState().top}"></ng-container>
                <app-record-button-icon [disabled]="(!me().duration && !_roomService.room.timeLeft) || !connected || currentStatus() === TranslateStatus.PREPARING"
                                        [other]="false"
                                        [lang]="(chatState().bottom.editMode ? chatState().bottom : chatState().top).language"></app-record-button-icon>
              </div>
              @if (chatState().top.editMode && landscape()) {
                <div class="w-1/2">&nbsp;</div>
              }
            } @else {
              <div class="w-[40%] flex justify-center items-center">
                <app-record-button [lang]="myOtherLang" [second]="true"
                                   [disabled]="(!me().duration && !_roomService.room.timeLeft) || !connected || currentStatus() === TranslateStatus.PREPARING || !myOtherLang"
                                   (noMinutesEvent)="noMinutesLeft()"
                                   (selectLangEvent)="changeLang(null, true)"
                ></app-record-button>
              </div>
              <div class="w-[20%] flex justify-center items-center pt-[1.3em]">
                <app-take-photo id="tour-step-8"
                                class="w-[32px] h-[32px]"
                                [buttonDisabled]="(!me().duration && !_roomService.room.timeLeft) || !connected || currentStatus() === TranslateStatus.PREPARING || !myOtherLang"></app-take-photo>
              </div>
              <div class="w-[40%] flex justify-center items-center">
                <app-record-button [lang]="myCurrentLang"
                                   id="tour-step-2"
                                   [canChangeLang]="false"
                                   (noMinutesEvent)="noMinutesLeft()"
                                   [disabled]="(!me().duration && !_roomService.room.timeLeft) || !connected || currentStatus() === TranslateStatus.PREPARING || !myOtherLang"
                                   (selectLangEvent)="changeLang(null, false);"
                ></app-record-button>
              </div>
            }
          </div>
        } @else {
          @if (me()?.preferences?.micPosition === MicPosition.LEFT) {
            <ng-container *ngTemplateOutlet="multiActionsLeftHand"></ng-container>
          } @else {
            <ng-container *ngTemplateOutlet="multiActionsRightHand"></ng-container>
          }
        }
      </div>
    </div>
  </div>
</ng-template>

<ng-template #multiActionsRightHand>
  <div class="w-full flex mb-8 items-end">
    <div class="w-full flex relative items-end border border-gray-300 ml-2.5">
      <div class="flex ml-2.5 mb-2 photo-button">
        <app-take-photo
          [buttonDisabled]="(!me().duration && !_roomService.room.timeLeft) || !connected || currentStatus() === TranslateStatus.PREPARING || !myOtherLang || !photoButton()"></app-take-photo>
      </div>

      <div class="chat-input-container flex-auto mr-2.5 p-3 overflow-y-auto">
        <ion-textarea #messageBox
                      placeholder="{{'text.message' | transloco}}"
                      [formControl]="messageCtrl"
                      [value]="messageCtrl.value"
                      [autoGrow]="true"
                      class="text-base min-h-[37px] max-h-[70px] textarea-input"
                      [ngClass]="{'dir-rtl': isRtl(me().language), 'dir-ltr': !isRtl(me().language)}"
                      (ionInput)="togglePhotoButton()"
                      rows="1"
                      (keydown.control.enter)="sendMessage(null, me().language, $event)"
        ></ion-textarea>
      </div>
    </div>
    @if (messageCtrl.value?.trim()) {
      <div class="stop-record-button stop-record-button_small ml-2.5 mr-5 mt-3" (click)="sendMessage(null, me().language, $event)"
           [class.disabled]="currentStatus() === TranslateStatus.PREPARING || (!me().duration && !_roomService.room.timeLeft) || messageCtrl.invalid || !connected">
        @if (messagePending) {
          <ion-icon name="sync-outline" class="text-4xl spin-animation"></ion-icon>
        } @else {
          <ion-icon name="send-outline" class="text-4xl"></ion-icon>
        }
      </div>
    } @else {
      <div class="stop-record-button stop-record-button_small ml-2.5 mr-5 mt-3" (click)="startRecording(true, me().language)"
           [class.disabled]="currentStatus() === TranslateStatus.PREPARING || (!me().duration && !_roomService.room.timeLeft) || !connected">
        <ion-icon name="mic" class="text-6xl"></ion-icon>
      </div>
    }
  </div>
</ng-template>

<ng-template #multiActionsLeftHand>
  <div class="w-full flex relative items-end">
    @if (messageCtrl.value?.trim()) {
      <div class="stop-record-button stop-record-button_small ml-5 mr-2.5 mt-3 mb-8" (click)="sendMessage(null, me().language, $event)"
           [class.disabled]="currentStatus() === TranslateStatus.PREPARING || (!me().duration && !_roomService.room.timeLeft) || !connected">
        @if (messagePending) {
          <ion-icon name="sync-outline" class="text-4xl spin-animation"></ion-icon>
        } @else {
          <ion-icon name="send-outline" class="text-4xl"></ion-icon>
        }
      </div>
    } @else {
      <div class="stop-record-button stop-record-button_small ml-5 mr-2.5 mt-3 mb-8" (click)="startRecording(true, me().language)"
           [class.disabled]="currentStatus() === TranslateStatus.PREPARING || (!me().duration && !_roomService.room.timeLeft) || !connected">
        <ion-icon name="mic" class="text-4xl"></ion-icon>
      </div>
    }
    <div class="chat-input-container flex-auto ml-2.5 mt-3 mr-5 mb-8">
      <ion-textarea #messageBox
                    placeholder="{{'text.message' | transloco}}"
                    [formControl]="messageCtrl"
                    [value]="messageCtrl.value"
                    [autoGrow]="true"
                    class="text-base min-h-[34px]"
                    [ngClass]="{'dir-rtl': isRtl(me().language), 'dir-ltr': !isRtl(me().language)}"
                    (keydown.control.enter)="sendMessage(null, me().language, $event)"
      ></ion-textarea>
    </div>
    @if (photoButton()) {
      <div class="flex mr-5 mt-3 mb-13 photo-button">
        <app-take-photo
          [buttonDisabled]="(!me().duration && !_roomService.room.timeLeft) || !connected || currentStatus() === TranslateStatus.PREPARING || !myOtherLang"></app-take-photo>
      </div>
    }
  </div>
</ng-template>

<ng-template #multiModeChat>
  @for (t of text; track t; let first = $first; let last = $last; let i = $index) {

    @if (first || ((text[i - 1].time * 1000) | date: 'd') !== ((t.time * 1000) | date: 'd')) {
      <div
        class="my-3 flex items-center justify-center"
      >
        <div class="flex-auto border-b border-b-gray-300"></div>
        <div
          class="text-secondary mx-4 flex-0 text-sm font-semibold leading-5"
        >
          {{
            (t.time * 1000) | date: 'dd.MM.yyyy'
          }}
        </div>
        <div class="flex-auto border-b border-b-gray-300"></div>
      </div>
    }

    @if (t.type === ChatMessageType.MESSAGE) {
      <ng-container *ngTemplateOutlet="chatMessage; context: {$implicit: t}"></ng-container>
    } @else if (t.type === ChatMessageType.USER_JOIN) {
      <ng-container *ngTemplateOutlet="userJoinedMessage; context: {$implicit: t}"></ng-container>
    } @else if (t.type === ChatMessageType.USER_LEFT) {
      <ng-container *ngTemplateOutlet="userLeftMessage; context: {$implicit: t}"></ng-container>
    } @else if (t.type === ChatMessageType.SUMMARY) {
      <ng-container *ngTemplateOutlet="summaryMessage; context: {$implicit: t}"></ng-container>
    } @else if (t.type === ChatMessageType.PROTOCOL) {
      <ng-container *ngTemplateOutlet="protocolMessage; context: {$implicit: t}"></ng-container>
    } @else if (t.type === ChatMessageType.TRANSCRIPT) {
      <ng-container *ngTemplateOutlet="transcriptMessage; context: {$implicit: t}"></ng-container>
    }
  }
</ng-template>

<ng-template #multiModeChatWindow>
  <ion-content #scrollArea>
    <ion-infinite-scroll position="top" (ionInfinite)="loadMoreMessages($event)" [disabled]="empty">
      <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
    </ion-infinite-scroll>

    <ng-container *ngTemplateOutlet="multiModeChat"></ng-container>
    @if (hasMessages && users.length <= 1) {
      <app-archive-chat-message [roomId]="_roomService.room.roomId"></app-archive-chat-message>
    }
  </ion-content>
  @if (commonError?.message && commonError.code > 0) {
    <div class="w-full absolute bottom-0">
      <app-chat-error [error]="commonError"></app-chat-error>
    </div>
  }
  @if (connectionError?.message) {
    <div class="w-full absolute bottom-0">
      <app-chat-error [error]="connectionError" [translate]="true"></app-chat-error>
    </div>
  }
</ng-template>

<ng-template #singleModeChatWindow>
  <div class="flex flex-col flex-auto shrink relative" [class.overflow-hidden]="_roomService.mode() === RoomMode.SINGLE">
    <div class="flex max-sm:flex-col w-full h-full relative" [class.flex-row-reverse]="me().preferences?.micPosition === MicPosition.LEFT">
      <div class="single-chat-window relative flex flex-col flex-1 bg-white max-sm:mb-1 max-sm:h-1/2 sm:mr-2 pb-2 overflow-hidden border border-gray-200"
           [class.inactive]="copyLang && me().secondLanguage !== copyLang"
           [class.collapse]="!landscape() && chatState().bottom.editMode"
           [class.expand]="!landscape() && chatState().top.editMode">
        <ng-container
          *ngTemplateOutlet="singleChatPanel; context: {$implicit: me().secondLanguage, chat: chatState().top, panel: 1, text: me().secondLanguage === singleText?.originalLang ? singleText?.originalText : singleText?.szdMessages?.[me().secondLanguage], time: singleText?.time}"></ng-container>
        @if (changeLangTranslationPending) {
          <app-loader></app-loader>
        }
      </div>
      <div class="single-chat-window relative flex flex-col flex-1 bg-white max-sm:mt-1 max-sm:h-1/2 sm:ml-2 overflow-hidden pb-2 border border-gray-200"
           [class.inactive]="copyLang && me().language !== copyLang"
           [class.collapse]="!landscape() && chatState().top.editMode"
           [class.expand]="!landscape() && chatState().bottom.editMode">
        <ng-container
          *ngTemplateOutlet="singleChatPanel; context: {$implicit: me().language, chat: chatState().bottom, panel: 2, text: me().language === singleText?.originalLang ? singleText?.originalText : singleText?.szdMessages?.[me().language], time: singleText?.time}"></ng-container>
        @if (changeSecondLangTranslationPending) {
          <app-loader></app-loader>
        }
      </div>
      @if (commonError?.message && commonError.code > 0) {
        <div class="w-full absolute bottom-0">
          <app-chat-error [error]="commonError"></app-chat-error>
        </div>
      }
      @if (connectionError?.message) {
        <div class="w-full absolute bottom-0">
          <app-chat-error [error]="connectionError" [translate]="true"></app-chat-error>
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #singleChatPanel let-lang let-text="text" let-time="time" let-panel="panel" let-chat="chat">
  <div class="flex justify-between items-center px-3 py-2 relative">
    @if (copyTime && copyTime === time && copyLang && copyLang === lang) {
      <div class="bg-black text-white py-1.5 px-3 text-center text-xs absolute max-w-32 top-[5px] left-0 right-0 m-auto">{{ 'text.copied' | transloco }}</div>
    }
    @if (lang === me().secondLanguage) {
      <div class="text-base font-bold capitalize cursor-pointer" [class.text-gray-400]="!lang" (click)="changeLang(chat, true)">
        {{ (lang || 'label.choose') | transloco }}
      </div>
    } @else {
      <div class="text-base font-bold capitalize cursor-pointer" (click)="changeLang(chat, false)">
        {{ lang | transloco }}
      </div>
    }
    <div class="flex items-center justify-end">
      @if (chat.editMode) {
        <div class="w-[30px] h-[30px] flex items-center justify-center overflow-hidden">
          <ion-icon name="close-outline" class="cursor-pointer text-5xl" (click)="closeEditableText(chat)"></ion-icon>
        </div>
      } @else {
        @if (chat.photoMode) {
          <div class="w-[30px] h-[30px] flex items-center justify-center overflow-hidden ltr:mr-2 rtl:ml-2">
            @if (chat.showOriginalText) {
              <ion-icon name="image-outline" class="text-4xl cursor-pointer" (click)="showOriginalTextSingleMode(chat)"></ion-icon>
            } @else {
              <ion-icon name="language-outline" (click)="showOriginalTextSingleMode(chat)"
                        class="text-4xl rtl:ml-2 cursor-pointer"></ion-icon>
            }
          </div>
        }
        <div class="w-[30px] h-[30px] flex items-center justify-center overflow-hidden" id="tour-step-5">
          @if (text) {
            @if (text.length < MAX_MESSAGE_LENGTH) {
              <ion-icon name="{{time === lastTime && lang === readLanguage ? 'stop-circle-outline' : 'volume-high-outline'}}"
                        (click)="readText(time, lang, text)"
                        class="cursor-pointer text-4xl"
                        [class.active]="time === lastTime && lang === readLanguage"></ion-icon>
            } @else {
              <ion-icon name="volume-mute-outline"
                        class="cursor-pointer text-4xl ltr:ml-2 opacity-50"></ion-icon>
            }
          } @else {
            <ion-icon name="volume-mute-outline"
                      class="text-gray-400 cursor-pointer text-4xl ltr:ml-2 opacity-50"></ion-icon>
          }
        </div>
      }
    </div>
  </div>
  @if (chat.photoMode && !chat.editMode && !chat.showOriginalText) {
    <div class="photo-container flex flex-col flex-auto justify-center items-center overflow-hidden">
      <img [src]="environment.serverUrl + '/v1/photos/' + chat.photo" alt="" class="photo" #zoomable (click)="showImage(environment.serverUrl + '/v1/photos/' + chat.photo)"/>
    </div>
  }
  <ion-content class="pb-2">
    <quill-editor class="text-base px-3 flex-auto h-full w-full"
                  [ngClass]="{'dir-rtl': isRtl(lang), 'dir-ltr': !isRtl(lang)}"
                  [formControl]="chat.form"
                  [class.hidden]="chat.photoMode && !chat.editMode && !chat.showOriginalText"
                  (onEditorCreated)="initEditor(chat, $event)"
                  (click)="enterEditTextMode(chat)"
    ></quill-editor>
  </ion-content>

  <div class="flex items-center justify-between px-3 pt-2">
    @if (!chat.editMode) {
      <div class="text-sm text-gray-400">
        @if (chat.photoMode && !chat.editMode && !chat.photoDesc) {
          {{ 'text.original.language' | transloco: { lang: (singleText?.originalLang | transloco) } }}
        }&nbsp;
      </div>
    }
    @if (chat.editMode) {
      <div class="w-full h-[30px] flex justify-between items-center edit-text-icons">
        <div class="h-[30px] flex items-center">
          <ion-icon name="trash-outline" class="cursor-pointer text-3xl ltr:mr-4 rtl:ml-4" (mousedown)="$event.preventDefault()" (click)="clearPanelMessage(chat)"></ion-icon>
          <ion-icon name="return-up-back-outline" class="cursor-pointer text-3xl ltr:mr-4 rtl:ml-4" (mousedown)="$event.preventDefault()" (click)="undoText(chat)"></ion-icon>
          <ion-icon name="return-up-forward-outline" class="cursor-pointer text-3xl ltr:mr-4 rtl:ml-4" (mousedown)="$event.preventDefault()" (click)="redoText(chat)"></ion-icon>
        </div>
        <div class="h-[30px] flex items-center">
          @if (aiImprovePending()) {
            <ion-icon name="sync-outline" class="text-3xl ltr:ml-4 rtl:mr-4 spin-animation"></ion-icon>
          } @else {
            <ion-icon name="sparkles-outline" class="cursor-pointer text-3xl ltr:ml-4 rtl:mr-4" (mousedown)="$event.preventDefault()" (click)="aiImprove(chat)"></ion-icon>
          }
          <ion-icon name="clipboard-outline" class="cursor-pointer text-3xl ltr:ml-4 rtl:mr-4" (mousedown)="$event.preventDefault()" (click)="pasteToChatWindow(chat)"></ion-icon>
          <ion-icon name="copy-outline" class="text-3xl cursor-pointer ltr:ml-4 rtl:mr-4" (mousedown)="$event.preventDefault()" (click)="copyTextToCBFromChatWindow(chat)"></ion-icon>
        </div>
      </div>
    } @else {
      <div class="h-[30px] flex items-center">
<!--        <div class="w-[30px] h-[30px] ltr:ml-4 flex items-center justify-center" id="tour-step-6">-->
<!--          <ion-icon src="/assets/icons/awesome/icon-keyboard.svg" class="text-4xl cursor-pointer" (click)="toggleTextMode(chat, textEdit)"-->
<!--                    [class.active]="chat.editMode && chat.language === lang"></ion-icon>-->
<!--        </div>-->
        <div class="w-[30px] h-[30px] cursor-pointer ltr:ml-4 rtl:mr-4 flex justify-center items-center overflow-hidden" id="tour-step-4">
          <ion-icon name="copy-outline" class="text-3xl cursor-pointer" (click)="copyText(chat.language, chat.editor.getText(), time)"
                    [class.active]="copyLang && copyLang === lang"></ion-icon>
        </div>
      </div>
    }
  </div>
</ng-template>

<ng-template #sendMessageButton let-chat>
  <div class="flex justify-between send-message-button p-5 mr-4 max-w-[70%] h-[80px] cursor-pointer flex-auto items-center"
       [class.ml-3]="me()?.preferences?.micPosition === MicPosition.LEFT || isRtl(me().preferences?.interfaceLanguage)"
       [class.mr-3]="me()?.preferences?.micPosition === MicPosition.RIGHT"
       [class.disabled]="!chat.contentChanged || (!me().duration && !_roomService.room.timeLeft) || (_roomService.mode() === RoomMode.SINGLE && !me().secondLanguage)"
       (mousedown)="$event.preventDefault()"
       (click)="sendMessage(chat, null, $event)">
    <div class="title uppercase line-clamp-1 text-3xl flex items-center">{{ 'label.translate' | transloco }}</div>
    <div class="flex items-center">
      @if (messagePending) {
        <ion-icon name="sync-outline" class="text-4xl spin-animation"></ion-icon>
      } @else {
        <ion-icon name="send-outline" class="text-4xl"></ion-icon>
      }
    </div>
  </div>
</ng-template>
