@if (tariff?.id) {
  <div class="border border-gray-300 p-4" [class.bg-color-1]="me()?.tariff?.uid === tariff.uid">
    <div class="flex justify-between pb-2.5 border-b border-b-gray-300 items-center">
      <app-tariff-title [tariffId]="tariff.uid"></app-tariff-title>
      <div class="title text-4xl">{{ tariff.price | currency: tariff.currency }}</div>
    </div>
    <div class="flex mt-2.5">
      <div class="w-1/2">
        <div class="text-base">{{ 'title.type' | transloco }}</div>
        <div class="font-semibold text-lg">{{ (tariff.uid === TariffEnum.PREPAID ? 'label.prepaid' : 'label.subscription') | transloco }}</div>
      </div>
      <div class="w-1/2">
        <div class="text-base">{{ 'title.included.credits' | transloco }}</div>
        <div class="font-semibold text-lg">{{ (tariff.duration) }}</div>
      </div>
    </div>
    <div class="w-full flex mt-5">
      <div class="w-1/2 pr-1.5">
        <button class="button w-full stroked bordered" (click)="showTariffDetails()" [disabled]="pending">{{
            (itemState === 'expanded' ? 'button.hide' : 'button.details') | transloco
          }}
        </button>
      </div>
      <div class="w-1/2 pl-1.5 flex items-center justify-center">
        @if (me()?.tariff?.uid !== tariff.uid && tariff.uid !== TariffEnum.PREPAID) {
<!--          TODO -->
<!--          <button class="button w-full" (click)="buyTariff()" [class.loading]="pending" [disabled]="pending">{{ 'button.upgrade' | transloco }}</button>-->
        } @else if (me()?.tariff?.uid === tariff.uid) {
          <div class="flex flex-col justify-center items-center">
            <div class="text-4xl title text-center uppercase link-color leading-none">{{ 'label.your.tariff' | transloco }}</div>
            @if (tariff.uid !== TariffEnum.PREPAID) {
              @if (!tariff.canceled) {
                <div class="leading-none"><a class="text-red-600 cursor-pointer text-base leading-none" (click)="cancelSubscription()">{{ 'link.cancel' | transloco }}</a></div>
              } @else {
                <div class="leading-none"><a class="text-red-600 cursor-pointer text-base leading-none">{{ 'label.until' | transloco }}
                  : {{ tariff.cancelDate | utcDate: 'dd.MM.yyyy' }}</a></div>
              }
            }
          </div>
        }
      </div>
    </div>
    <div class="w-full overflow-hidden" [@expandCollapse]="itemState" [class.mt-5]="itemState === 'expanded'">
      @for (desc of tariffDescription; track desc; let last = $last) {
        <div class="flex items-center py-2.5 border-t border-t-gray-300">
          <div class="w-[20px] h-[20px]">
            <ion-icon name="checkmark-circle-outline" class="active text-2xl"></ion-icon>
          </div>
          <div class="text-base pl-2.5" [innerHTML]="desc | transloco"></div>
        </div>
      }
    </div>
  </div>
}
