import {
  AIChatError,
  AudioPlayerStatus,
  Chat,
  ChatMessageDto,
  ChatMessages,
  ChatMessageType,
  ChatResultType,
  ChatWindow,
  MessageTts,
  MicPosition,
  Paging,
  RecorderStatus,
  Room,
  RoomAction,
  RoomMode,
  RoomUser,
  RoomUserAction,
  SiteInfo,
  TranslateStatus,
  UserRole
} from '@shared/transport.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, effect, HostListener, inject, OnDestroy, OnInit, signal, Signal, untracked, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserState } from '@shared/store/user/user-state.service';
import { SiteInfoState } from '@shared/store/site/site-state.service';
import { ChatState } from '@shared/store/chat/chat-state.service';
import { RoomService } from '@shared/service/room.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NotyService } from '@shared/service/noty.service';
import { RxStompService } from '@shared/service/rx-stomp/rx-stomp.service';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { AuthService } from '@shared/service/auth/auth.service';
import { InfiniteScrollCustomEvent, IonicModule, Platform } from '@ionic/angular';
import { LangService } from '@shared/service/lang.service';
import { Pages } from '@app/pages';
import { ErrorObject } from '@shared/http-client/error-object.class';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { finalize, of, Subscription, switchMap } from 'rxjs';
import { AudioService } from '@shared/service/audio.service';
import { SaveBottomChatWindowAction, SaveChatAction, SaveTopChatWindowAction } from '@shared/store/chat/chat.actions';
import { ErrorCodes } from '@shared/errorcodes.const';
import { RxStompState } from '@stomp/rx-stomp';
import { SaveUserAction } from '@shared/store/user/user.actions';
import { DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { LoaderComponent } from '@shared/component/loader/loader.component';
import { FullScreenViewer } from 'iv-viewer';
import { addIcons } from 'ionicons';
import {
  alertCircle,
  arrowBackOutline,
  arrowForwardOutline,
  arrowUp,
  chatbubble,
  chatbubbles,
  clipboardOutline,
  closeOutline,
  copyOutline,
  documentText,
  ellipsisVertical,
  imageOutline,
  informationCircle,
  language,
  languageOutline,
  logOutOutline,
  mic,
  micOutline,
  newspaper,
  personAdd,
  reader,
  returnUpBackOutline,
  returnUpForwardOutline,
  scan,
  sendOutline,
  shareSocialOutline,
  sparklesOutline,
  stopCircleOutline,
  swapHorizontal,
  syncOutline,
  trashOutline,
  volumeHighOutline,
  volumeMuteOutline
} from 'ionicons/icons';
import { RecordButtonComponent } from '@shared/component/record-button/record-button.component';
import { ChatErrorComponent } from '@shared/component/ui/chat-error/chat-error.component';
import { InitialsAvatarComponent } from '@shared/component/ui/initials-avatar/initials-avatar.component';
import { IonContent, IonTextarea } from '@ionic/angular/standalone';
import { SmallNavMenuComponent } from '@shared/component/small-nav-menu/small-nav-menu.component';
import { DialogService } from '@shared/service/dialog.service';
import { TakePhotoComponent } from '@shared/component/take-photo/take-photo.component';
import { environment } from '@env/environment';
import { ArchiveChatMessageComponent } from '@shared/component/archive-chat-message/archive-chat-message.component';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { Clipboard } from '@capacitor/clipboard';
import { TourComponent } from '@shared/component/tour/tour.component';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { StorageService } from '@service/storage.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { UtilsService } from '@service/utils.service';
import { VoiceRecorder } from 'fpmk-capacitor-voice-recorder';
import { GenericResponse } from 'fpmk-capacitor-voice-recorder/dist/esm/definitions';
import { RecordButtonIconComponent } from '@comp/record-button-icon/record-button-icon.component';
import { QuillEditorComponent } from 'ngx-quill';
import { QuillModules } from 'ngx-quill/config/quill-editor.interfaces';

@UntilDestroy()
@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [
    TranslocoPipe,
    NgClass,
    LoaderComponent,
    DatePipe,
    IonicModule,
    NgTemplateOutlet,
    RecordButtonComponent,
    ChatErrorComponent,
    InitialsAvatarComponent,
    ReactiveFormsModule,
    SmallNavMenuComponent,
    TakePhotoComponent,
    ArchiveChatMessageComponent,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    TourComponent,
    RecordButtonIconComponent,
    QuillEditorComponent
  ],
  templateUrl: './chat.component.html',
  styleUrls: [ './chat.component.scss' ],
})
export class ChatComponent implements OnInit, OnDestroy {

  private readonly _store = inject(Store);
  private readonly _utilsService = inject(UtilsService);
  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  private siteInfo: Signal<SiteInfo> = this._store.selectSignal(SiteInfoState.getSiteInfo);
  protected readonly chatState: Signal<Chat> = this._store.selectSignal(ChatState.getChat);

  @ViewChild('scrollArea', { static: false })
  private scrollbar!: IonContent;

  @ViewChild('messageBox', { static: false })
  private messageBox: IonTextarea;

  protected readonly _roomService = inject(RoomService);
  protected readonly _platform = inject(Platform);

  private readonly _storageService = inject(StorageService);
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);
  private readonly _noty = inject(NotyService);
  private readonly _dialogService = inject(DialogService);
  private readonly _ws = inject(RxStompService);
  private readonly _translateService = inject(TranslocoService);
  private readonly _authService = inject(AuthService);
  private readonly _langService = inject(LangService);
  private readonly _notyService = inject(NotyService);

  protected readonly MAX_MESSAGE_LENGTH = 4000;
  protected readonly MAX_MESSAGE_LENGTH_V2 = 2500;
  protected readonly environment = environment;

  protected readonly TranslateStatus = TranslateStatus;
  protected readonly AudioPlayerStatus = AudioPlayerStatus;
  protected readonly RoomMode = RoomMode;
  protected readonly UserRole = UserRole;
  protected readonly Pages = Pages;
  protected readonly ChatMessageType = ChatMessageType;
  protected readonly MicPosition = MicPosition;
  protected readonly ChatResultType = ChatResultType;

  protected currentStatus = signal<TranslateStatus | RoomAction>(TranslateStatus.READY);
  protected audioPlayerStatus = signal<AudioPlayerStatus>(AudioPlayerStatus.READY);
  protected recorderStatus = signal<RecorderStatus>(RecorderStatus.READY);

  protected pending = false;
  protected connected = false;
  protected isRecording = false;
  protected loaded = false;
  protected loading = false;
  protected muted = false;
  protected creator = false;
  protected volumeDisabled = false;
  protected speakMe = true;
  protected messagePending = false;
  protected keyboard = false;
  protected loadingMore = false;
  protected empty = false;
  protected hasMessages = false;
  protected skipTour = false;
  protected fullscreen = false;
  protected changeLangTranslationPending = false;
  protected changeSecondLangTranslationPending = false;

  protected text: ChatMessageDto[] = [];
  protected singleText: ChatMessageDto = {};
  protected roomUserAction!: RoomUserAction;
  protected commonError = new ErrorObject(-1, '');
  protected connectionError = new ErrorObject(-1, '');
  protected info = null;
  protected waitingUsers: RoomUser[] = [];
  protected speakLang = '';
  protected copyLang = '';
  protected copyTime = 0;
  protected showOriginalTexts = {};
  protected nowSpeaking: RoomUser[] = [];
  protected speakingText = '';
  protected readonly maxMessageLength = 64_000;
  protected volume: number = 100;
  protected lastTime = 0;
  protected readLanguage = '';
  protected messageCtrl: FormControl = new FormControl<string>('', [ Validators.minLength(1), Validators.maxLength(this.maxMessageLength) ]);
  protected summaryLoad = {};
  protected protocolLoad = {};
  protected transcriptLoad = {};
  protected landscape = signal(false);
  protected lastUsedLangs: string[] = [];
  protected photoButton = signal(true);
  protected viewer = new FullScreenViewer();

  private _paging: Paging = {
    currentPage: 1,
    totalPages: 0,
    pageSize: 20,
  };
  private _wsSubscription!: Subscription;
  private _wsStateSubscription!: Subscription;
  private _subscriptions: Subscription[] = [];

  @HostListener('window:resize', [ '$event' ])
  onResize() {
    this.landscape.set(this.isLandscape());
  }

  constructor(private _audioService: AudioService) {
    addIcons({
      ellipsisVertical,
      personAdd,
      alertCircle,
      documentText,
      swapHorizontal,
      newspaper,
      reader,
      chatbubbles,
      chatbubble,
      shareSocialOutline,
      language,
      volumeHighOutline,
      volumeMuteOutline,
      stopCircleOutline,
      languageOutline,
      copyOutline,
      closeOutline,
      imageOutline,
      arrowForwardOutline,
      syncOutline,
      arrowUp,
      mic,
      micOutline,
      scan,
      informationCircle,
      logOutOutline,
      sendOutline,
      clipboardOutline,
      arrowBackOutline,
      returnUpBackOutline,
      returnUpForwardOutline,
      trashOutline,
      sparklesOutline,
    });
    effect(() => {
      this.me();
      untracked(() => {
        const state = this.chatState();
        state.top.language = this.me().secondLanguage;
        state.bottom.language = this.me().language;
        state.bottom.nativeLanguage = true;
        this._store.dispatch(new SaveChatAction(state));
      });
    });
    const payload = {
      top: {
        showOriginalText: false,
        photoMode: false,
        editMode: false,
        contentChanged: false,
        language: '',
        nativeLanguage: false,
        form: new FormControl<string>(''),
        editor: null
      },
      bottom: {
        showOriginalText: false,
        photoMode: false,
        editMode: false,
        contentChanged: false,
        language: '',
        nativeLanguage: false,
        form: new FormControl<string>(''),
        editor: null
      },
    };
    payload.top.form.valueChanges.subscribe(() => {
      payload.top.contentChanged = true;
    });
    payload.bottom.form.valueChanges.subscribe(() => {
      payload.bottom.contentChanged = true;
    });
    this._store.dispatch(new SaveChatAction(payload));
  }

  freshInit(): void {
    this.currentStatus.set(TranslateStatus.READY);
    this.audioPlayerStatus.set(AudioPlayerStatus.READY);
    this.recorderStatus.set(RecorderStatus.READY);
    this.text = [];
    this.hasMessages = false;
    this.singleText = {};
    this.dispatchSingleMessage({});
    this.commonError = new ErrorObject(-1, '');
    this.roomUserAction = {};
    this.info = null;
    this.waitingUsers = [];
    this.speakLang = '';
    this.copyLang = '';
    this.copyTime = 0;
    this.showOriginalTexts = {};
    this.nowSpeaking = [];
    this.speakingText = '';
    this.volume = 100;
    this.lastTime = 0;
    this.readLanguage = '';
    this._paging = {
      currentPage: 1,
      totalPages: 0,
      pageSize: 20,
    };
    this.pending = false;
    this.connected = false;
    this.isRecording = false;
    this.loaded = false;
    this.loading = false;
    this.muted = false;
    this.creator = false;
    this.volumeDisabled = false;
    this.speakMe = true;
    this.messagePending = false;
    this.keyboard = false;
  }

  ngOnInit(): void {
    this.initChat().then();
  }

  private async initChat() {
    this.landscape.set(this.isLandscape());
    this._storageService.get('skipTour').then((res: string) => {
      this.skipTour = res === 'true';
    });
    this.lastUsedLangs = await this._storageService.lastUsedLangs();

    this._route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params: Params) => {
        this.freshInit();
        const mode = params[ 'mode' ] === 'conference' ? RoomMode.MULTI : RoomMode.SINGLE;
        this.startChat(mode);
      });

    this._route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params: Params) => {
        if (params[ 'invite' ] && this._roomService.mode() === RoomMode.MULTI) {
          this.showInviteQRCode();
        }
      });
    try {
      SplashScreen.hide().then(() => {
        this._utilsService.statusBar();
      });
    } catch (e) {
      //
    }
  }

  runChat(mode: RoomMode): void {
    this.commonError = new ErrorObject(-1, null);
    if (this.me().role === UserRole.TEMP_USER && mode === RoomMode.SINGLE) {
      this.commonError = new ErrorObject(1, 'message.register');
      return;
    }
    this.loading = true;
    this._storageService.roomId().then(roomId => {
      if (roomId) {
        this._roomService.mode.set(mode);
        this.afterRoomInit();
      } else {
        this._storageService.clearRoomId();
        this._roomService.joinMyRoom(mode)
          .pipe(untilDestroyed(this), finalize(() => {
            setTimeout(() => {
              this.loading = false;
            }, 200);
          }))
          .subscribe({
            next: () => {
              this.afterRoomInit();
            }, error: err => {
              this.commonError = err;
            }
          });
      }
    });
  }

  private afterRoomInit() {
    if (!this._roomService.room.timeLeft) {
      this.showErrorPermanent(ErrorCodes.NO_MINUTES_LEFT, 'error.translation.minutes.expired');
    }
    if (this._roomService.mode() >= 0 && this._roomService.room?.roomId && !this.text?.length) {
      this._paging.currentPage = 1;
      if (this._roomService.mode() === RoomMode.MULTI) {
        this.loadMessages(this._roomService.room?.roomId);
      } else {
        this.loadLastMessage(this._roomService.room?.roomId);
      }
    }
    this.initComponent();
  }

  private startChat(mode: RoomMode) {
    const user = this.me();
    if (!user || (this.me().role === UserRole.TEMP_USER && mode === RoomMode.SINGLE)) {
      this._router.navigate([ '/', Pages.LOGIN ]);
      return;
    } else {
      this.runChat(mode);
    }
  }

  private async initComponent() {
    this._audioService.init();
    this.loading = true;
    this._authService.refreshTokenPeriodically();
    const roomId = await this._storageService.roomId();
    if (!this._roomService.room.roomId) {
      if ((this.me().role === UserRole.COMPANY_USER || this.me().role === UserRole.COMPANY_HOST || this.me().role === UserRole.COMPANY_ADMIN) && this.siteInfo().corporate) {
        this._router.navigate([ '/', Pages.COMPANY, Pages.PORTAL, Pages.DASHBOARD ]);
      } else if ((this.me().role === UserRole.USER || this.me().role === UserRole.TEMP_USER) && roomId) {
        this._router.navigate([ '/', Pages.JOIN_ROOM, roomId ]);
      }
      this._dialogService.closeAll();
      return;
    }
    if (this._wsSubscription) {
      this._wsSubscription.unsubscribe();
    }
    this._wsSubscription = this._ws.connected$
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.loaded = true;
        if (res === RxStompState.OPEN) {
          this.connectionError = new ErrorObject(-1, '');
          this.connected = true;
          this.initEvents();
          if (this._roomService.room.roomId) {
            this._roomService.sendConnectedMessage();
            this.afterJoin(this._roomService.room);
          }
        }
      });
    if (this._wsStateSubscription) {
      this._wsStateSubscription.unsubscribe();
    }
    this._wsStateSubscription = this._ws.connectionState$
      .subscribe((res) => {
        if (res === RxStompState.CLOSED) {
          this.connected = false;
          this.connectionError = new ErrorObject(-1, 'message.connecting');
        }
      });
    try {
      KeepAwake.keepAwake().then();
    } catch (e) {
      //
    }
    setTimeout(() => {
      this.loading = false;
    }, 300);
    window.onbeforeunload = () => {
      this.ngOnDestroy();
    }
  }

  ngOnDestroy(): void {
    this._roomService.unsubscribeFromAll();
    if (this._wsSubscription) {
      this._wsSubscription.unsubscribe();
    }
    if (this._subscriptions?.length) {
      this._subscriptions.forEach(sub => {
        if (sub) {
          sub.unsubscribe();
        }
      });
      this._subscriptions = [];
    }
    this._roomService.disconnect();
    this._store.dispatch(new SaveUserAction(this.me()));
    this._authService.stopRefreshTokenPeriodically();
    this._audioService.abortRecording();
    KeepAwake.allowSleep().then();
  }

  get myOtherLang(): string {
    return this.me().secondLanguage || '';
  }

  get myCurrentLang(): string {
    return this.me().language;
  }

  readText(time: number, lang: string, text: string): void {
    let commonLanguage = this._langService.findLang(lang);
    if (text.length > this.MAX_MESSAGE_LENGTH || commonLanguage?.version === 'v2' && text.length > this.MAX_MESSAGE_LENGTH_V2) {
      this._noty.error('error.message.too.long');
      return;
    }
    if (this._audioService.readText(time, lang)) {
      this.audioPlayerStatus.set(AudioPlayerStatus.READY);
      this.readLanguage = '';
      this.lastTime = 0;
    } else {
      this.audioPlayerStatus.set(AudioPlayerStatus.PLAYING);
      this.readLanguage = lang;
      this.lastTime = time;
    }
  }

  startRecording(speakMe: boolean, lang: string): void {
    if (this.currentStatus() === TranslateStatus.PREPARING) {
      return;
    }
    if (this.info || !this.connected) {
      return;
    }
    this.commonError = new ErrorObject(-1, '');
    if (!this.me().duration && !this._roomService.room.timeLeft) {
      this.showErrorPermanent(ErrorCodes.NO_MINUTES_LEFT, 'error.translation.minutes.expired');
      return;
    }
    this.speakMe = speakMe;
    this.speakLang = lang;
    if (this._roomService.mode() === RoomMode.SINGLE && !this._roomService.getMyOtherLang()) {
      this.showError(1, 'error.second.language.empty');
      this.scrollToBottom();
    }
    VoiceRecorder.requestAudioRecordingPermission().then((res: GenericResponse) => {
      if (res.value) {
        this._dialogService.startRecord(this.speakMe, this.speakLang, this._roomService.mode())
          .subscribe(() => {
            this.recordEnded();
          });
      } else {
        this._dialogService.microphoneAccess();
      }
    }).catch(error => console.log(error));
  }

  changeLang(chat: ChatWindow, firstSelected: boolean, onlySelectLang: boolean = false): void {
    if (chat?.editMode) {
      return;
    }
    this._dialogService.selectLanguage(this._roomService.getMyCurrentLang(), this._roomService.getMyOtherLang(), firstSelected, onlySelectLang)
      .then(lang => {
        if (lang) {
          this.saveMyLangs(firstSelected, lang);
        }
      });
  }

  get users(): RoomUser[] {
    return this._roomService.users;
  }

  showInviteQRCode(): void {
    this._dialogService.qrCode(this._roomService.room.roomId);
  }

  shareChat(): void {
    if (this.me().role === UserRole.TEMP_USER) {
      return;
    }
    this.pending = true;
    this._roomService.joinMyRoom(RoomMode.MULTI)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe({
        next: () => {
          this._storageService.clearRoomId();
          this._router.navigate([ '/', Pages.CHAT ], { queryParams: { invite: true, mode: 'conference' } });
        }
      });
  }

  volumeChange($event: number): void {
    this._audioService.setVolume($event / 100);
  }

  recordEnded(): void {
    this.currentStatus.set(TranslateStatus.READY);
  }

  copyText(lang: string, txt: string, time: number): void {
    if (this.copyLang && this.copyTime) {
      return;
    }
    if (!txt || !txt.trim()) {
      return;
    }
    let text = txt.replaceAll('<br/>', '\n');
    text = text.replaceAll('<br>', '\n');
    Clipboard.write({ string: text }).then();
    this.copyLang = lang;
    this.copyTime = time;
    setTimeout(() => {
      this.copyLang = '';
      this.copyTime = 0;
    }, 2000);
  }

  // copyTextToCB(): void {
  //   let text = this.messageCtrl.value.replaceAll('<br/>', '\n');
  //   text = text.replaceAll('<br>', '\n');
  //   Clipboard.write({ string: text }).then();
  //   this.copyTime = new Date().getTime();
  //   setTimeout(() => {
  //     this.copyTime = 0;
  //   }, 2000);
  // }

  copyTextToCBFromChatWindow(chat: ChatWindow): void {
    Clipboard.write({ string: chat.editor.getText() }).then();
    this.copyTime = new Date().getTime();
    setTimeout(() => {
      this.copyTime = 0;
    }, 2000);
  }

  sendMessage(chat: ChatWindow, language: string, $event: any): void {
    const lang = chat?.language || language;
    if (this.messagePending || !this.connected || chat && !chat.contentChanged) {
      return;
    }
    let message: string;
    if (chat?.editor) {
      message = chat.editor.getText().trim();
      this.messageCtrl.setValue(message);
    } else {
      message = this.messageCtrl.value?.trim();
    }
    this.commonError = new ErrorObject(-1, '');
    if (!this.me().duration && !this._roomService.room.timeLeft) {
      this.showErrorPermanent(ErrorCodes.NO_MINUTES_LEFT, 'error.translation.minutes.expired');
      return;
    }
    if (this.messageCtrl.hasError('maxlength')) {
      this.showError(ErrorCodes.EMPTY_CHAT_MESSAGE, 'error.chat.message.max.length', { maxLength: this.maxMessageLength });
      return;
    }
    if (this.messageCtrl.invalid || this.messagePending || !this.messageCtrl.value || this.messageCtrl.value === '<br>' || this.messageCtrl.value === '<br/>') {
      this.showError(ErrorCodes.EMPTY_CHAT_MESSAGE, 'error.empty.chat.message');
      return;
    }
    if (this._roomService.mode() === RoomMode.SINGLE && !this.me().secondLanguage) {
      this.showError(1, 'error.second.language.empty');
      return;
    }
    $event.preventDefault();
    this.messageCtrl.disable();
    this.messagePending = true;
    this._roomService.sendMessage(message, lang)
      .pipe(untilDestroyed(this), finalize(() => {
        if (chat) {
          chat.prevText = null;
          this.closeEditableText(chat);
        }
        this.messagePending = false;
        this.messageCtrl.enable();
        this.messageBox?.setFocus();
      }))
      .subscribe({
        next: () => {
          this.messageCtrl.reset();
        },
        error: (err) => {
          this.showError(ErrorCodes.Internal, err.message);
        }
      });
  }

  // sendEditableMessage(chat: ChatWindow, $event: any): void {
  //   if (this.messagePending || !chat.contentChanged) {
  //     return;
  //   }
  //   this.commonError = new ErrorObject(-1, '');
  //   const message = chat.editor?.innerHTML?.trim();
  //   if (!message.length || message === '<br>' || message === '<br/>' || this.messagePending) {
  //     this.showError(ErrorCodes.EMPTY_CHAT_MESSAGE, 'error.empty.chat.message');
  //     return;
  //   }
  //   if (!this.me().duration && !this._roomService.room.timeLeft) {
  //     this.showErrorPermanent(ErrorCodes.NO_MINUTES_LEFT, 'error.translation.minutes.expired');
  //     return;
  //   }
  //   if (message.length > this.MAX_MESSAGE_LENGTH) {
  //     this.showError(ErrorCodes.EMPTY_CHAT_MESSAGE, 'error.chat.message.max.length', { maxLength: this.maxMessageLength });
  //     return;
  //   }
  //   if (this._roomService.mode() === RoomMode.SINGLE && !this.me().secondLanguage) {
  //     this.showError(1, 'error.second.language.empty');
  //     return;
  //   }
  //   $event.preventDefault();
  //   this.messagePending = true;
  //   if (chat.messageId) {
  //     this._roomService.editMessage(chat.messageId, message, chat.language!)
  //       .pipe(untilDestroyed(this), finalize(() => {
  //         this.messagePending = false;
  //         chat.prevText = null;
  //         if (chat.editMode) {
  //           this.closeEditableText(chat);
  //         }
  //       }))
  //       .subscribe({
  //         next: (msg) => {
  //           this.singleText = msg;
  //           this.dispatchSingleMessage(this.singleText);
  //         },
  //         error: (err) => {
  //           this.showError(ErrorCodes.Internal, err.message);
  //         }
  //       });
  //     return;
  //   }
  //   this._roomService.sendMessage(message, chat.language!)
  //     .pipe(untilDestroyed(this), finalize(() => {
  //       this.messagePending = false;
  //       chat.prevText = null;
  //       if (chat.editMode) {
  //         this.closeEditableText(chat);
  //       }
  //     }))
  //     .subscribe({
  //       next: () => {
  //       },
  //       error: (err) => {
  //         this.showError(ErrorCodes.Internal, err.message);
  //       }
  //     });
  // }

  showOriginalTextSingleMode(chat: ChatWindow): void {
    if (!chat.form.value) {
      this.makeTranslation({ firstLang: this.me().secondLanguage, secondLang: this.me().language }, true)
        .pipe(untilDestroyed(this))
        .subscribe(res => {
          this.singleText = res;
          this.dispatchSingleMessage(this.singleText);
          chat.showOriginalText = !chat.showOriginalText;
          this.dispatchChatWindow(chat);
        });
      return;
    }
    chat.showOriginalText = !chat.showOriginalText;
    this.dispatchChatWindow(chat);
  }

  showOriginalTextMultiMode(chat: ChatMessageDto): void {
    this.showOriginalTexts[ chat.time ] = !this.showOriginalTexts[ chat.time ];
  }

  acceptUsersDialog(): void {
    // TODO
    // this._dialogService.acceptUsers(this._roomService.waitingUsers).subscribe();
  }

  loadMoreMessages(ev: InfiniteScrollCustomEvent): void {
    if (this.loadingMore || this.empty) {
      return;
    }
    this.loadingMore = true;
    this._paging.currentPage++;
    this._roomService.lastMessages(this._roomService.room.roomId, this._paging.pageSize, (this._paging.currentPage - 1) * this._paging.pageSize)
      .pipe(untilDestroyed(this), finalize(() => this.loadingMore = false))
      .subscribe((res: ChatMessages) => {
        this._paging.totalPages = res.total;
        this.text.unshift(...res.list);
        // this.text = res.list.concat(this.text);
        this.hasMessages = this.text.findIndex(m => m.type === ChatMessageType.MESSAGE) >= 0;
        if (!res.list.length || res.list.length < this._paging.pageSize) {
          this.empty = true;
        }
        ev.target.complete();
      });
  }

  enterEditTextMode(chat: ChatWindow): void {
    if (chat.editMode) {
      this.dispatchChatWindow(chat);
      return;
    }
    if (this.chatState().top.editMode) {
      this.closeEditableText(this.chatState().top);
    } else if (this.chatState().bottom.editMode) {
      this.closeEditableText(this.chatState().bottom);
    }
    chat.editMode = true;
    if (!chat.prevText) {
      chat.prevText = chat.form.value;
    }
    this.dispatchChatWindow(chat);
    setTimeout(() => {
      if (chat.editor) {
        chat.editor.focus();
      }
    }, 50);
  }

  // toggleTextMode(chat: ChatWindow, textEdit: HTMLDivElement): void {
  //   chat.editMode = true;
  //   chat.editor = textEdit;
  //   chat.caretPos = 0;
  //   chat.prevText = chat.text;
  //   chat.text = '';
  //   chat.editor.innerHTML = '';
  //   this.dispatchChatWindow(chat);
  //   setTimeout(() => {
  //     if (textEdit) {
  //       textEdit.focus();
  //     }
  //   }, 50);
  // }

  showRoomInfo(): void {
    if (this.me().role === UserRole.TEMP_USER) {
      return;
    }
    this._dialogService.roomInfo(this._roomService.room);
  }

  showError(errorCode: ErrorCodes, message: string, params?: any): void {
    this.commonError = new ErrorObject(errorCode, message, params);
    setTimeout(() => {
      this.commonError = null;
    }, 4000)
  }

  showErrorPermanent(errorCode: ErrorCodes, message: string, params?: any): void {
    this._translateService.selectTranslate(message, params)
      .subscribe(res => {
        this.commonError = new ErrorObject(errorCode, res);
      });
  }

  noMinutesLeft(): void {
    this.showErrorPermanent(ErrorCodes.NO_MINUTES_LEFT, 'error.translation.minutes.expired');
  }

  showMessages(): void {
    this._dialogService.roomMessages(this._roomService.room.roomId);
  }

  showSummary(shareCode: string): void {
    if (this.summaryLoad[ shareCode ]) {
      return;
    }
    this.summaryLoad[ shareCode ] = true;
    this._roomService.getSharedSummary(shareCode)
      .pipe(untilDestroyed(this), finalize(() => this.summaryLoad[ shareCode ] = false))
      .subscribe(res => {
        this._dialogService.showSummary(res);
      });
  }

  showProtocol(shareCode: string): void {
    if (this.protocolLoad[ shareCode ]) {
      return;
    }
    this.protocolLoad[ shareCode ] = true;
    this._roomService.getSharedProtocol(shareCode)
      .pipe(untilDestroyed(this), finalize(() => this.protocolLoad[ shareCode ] = false))
      .subscribe(res => {
        this._dialogService.showProtocol(res, false);
      });
  }

  showTranscript(shareCode: string): void {
    if (this.transcriptLoad[ shareCode ]) {
      return;
    }
    this.transcriptLoad[ shareCode ] = true;
    this._roomService.getSharedTranscript(shareCode)
      .pipe(untilDestroyed(this), finalize(() => this.transcriptLoad[ shareCode ] = false))
      .subscribe(res => {
        this._dialogService.showTranscript(res, false);
      });
  }

  paste(text: string): void {
    if (text) {
      this.messageCtrl.setValue(text);
    }
  }

  // pasteToMessageBox() {
  //   Clipboard.read().then(text => {
  //     if (this._roomService.isSingleMode) {
  //       this.divMessageBox.nativeElement.innerHTML = text.value;
  //     }
  //     this.messageCtrl.setValue(text.value);
  //   });
  // }

  pasteToChatWindow(chat: ChatWindow) {
    Clipboard.read().then(text => {
      this.insertMessage(chat, text.value);
    });
  }

  isRtl(lang: string): boolean {
    return this._langService.isRtl(lang);
  }

  createSummary(type: ChatResultType): void {
    this._dialogService
      .createSummary(this._roomService.room, type)
      .then(res => {
        if (res) {
          this.showRoomInfo();
        }
      });
  }

  private isLandscape(): boolean {
    return screen.availWidth > screen.availHeight || screen.availWidth >= 640;
  }

  closeEditableText(chat: ChatWindow): void {
    if (chat.prevText) {
      chat.form.setValue(chat.prevText);
      chat.prevText = null;
    }
    chat.editMode = false;
    chat.contentChanged = false;
    chat.messageId = null;
    this.messageCtrl.reset();
    this.dispatchChatWindow(chat);
  }

  exitRoom(): void {
    this._dialogService
      .newRoom(this._roomService.room.roomId)
      .pipe(switchMap((res) => {
        if (res) {
          return this._roomService.joinMyRoom(this._roomService.mode());
        }
        return of(false);
      }))
      .subscribe({
        next: (res) => {
          if (!res) {
            return;
          }
          this._storageService.clearRoomId();
          this.freshInit();
          this.ngOnInit();
        }, error: err => {
          this._notyService.error(err.message);
        }
      });
  }

  leaveRoom() {
    this._dialogService.exitRoom(this._roomService.room.roomId)
      .subscribe(() => {
        this._dialogService.closeAll();
      });
  }

  // toggleFullscreen() {
  //   if (!this.fullscreen) {
  //     this.requestFullScreen(document.documentElement);
  //   } else {
  //
  //     document
  //       .exitFullscreen()
  //       .then(() => console.log("Document Exited from Full screen mode"))
  //       .catch((err) => console.error(err));
  //     this.fullscreen = false;
  //   }
  // }

  // requestFullScreen(el: any): boolean {
  //   // Supports most browsers and their versions.
  //   const requestMethod = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;
  //
  //   if (requestMethod) { // Native full screen.
  //     requestMethod.call(el);
  //     this.fullscreen = true;
  //   }
  //   return false;
  // }

  showImage(zoomable: string): void {
    this.viewer.show(zoomable);
  }

  private dispatchChatWindow(chat: ChatWindow): void {
    if (chat.nativeLanguage) {
      this._store.dispatch(new SaveBottomChatWindowAction(chat));
    } else {
      this._store.dispatch(new SaveTopChatWindowAction(chat));
    }
  }

  private saveMyLangs(firstSelected: boolean, lang: { firstLang: string; secondLang: string }, swap?: boolean) {
    this._roomService.setMyLangs(lang.secondLang, lang.firstLang)
      .pipe(untilDestroyed(this), finalize(() => this.swapLanguageLoader = false))
      .subscribe(() => {
        if (this._roomService.mode() === RoomMode.SINGLE) {
          if (swap) {
            this.chatState().top.language = lang.firstLang;
            this.dispatchChatWindow(this.chatState().top);
            this.chatState().bottom.language = lang.secondLang;
            this.dispatchChatWindow(this.chatState().bottom);
          } else {
            if (firstSelected) {
              this.chatState().top.language = lang.firstLang;
              this.dispatchChatWindow(this.chatState().top);
            } else {
              this.chatState().bottom.language = lang.secondLang;
              this.dispatchChatWindow(this.chatState().bottom);
            }
          }
          this.dispatchSingleMessage(this.singleText as ChatMessageDto);
          const user = this._roomService.setUserLangs(this.me(), lang.secondLang, lang.firstLang);
          this._store.dispatch(new SaveUserAction(user));
          if (lang.secondLang !== lang.firstLang && this.singleText[ 'id' ]) {
            this.makeTranslation(lang, firstSelected, swap).pipe(untilDestroyed(this))
              .subscribe(res => {
                this.singleText = res;
                this.dispatchSingleMessage(this.singleText);
              });
          }
        }
      });
  }

  private makeTranslation(lang: { firstLang: string; secondLang: string }, firstSelected: boolean, swap?: boolean) {
    if (firstSelected) {
      this.changeLangTranslationPending = true;
    } else {
      this.changeSecondLangTranslationPending = true;
    }
    if (swap) {
      this.changeLangTranslationPending = true;
      this.changeSecondLangTranslationPending = true;
    }
    return this._roomService.updateMessage(<number>this.singleText[ 'id' ], firstSelected ? lang.firstLang : lang.secondLang, swap)
      .pipe(untilDestroyed(this), finalize(() => {
        if (firstSelected) {
          this.changeLangTranslationPending = false;
        } else {
          this.changeSecondLangTranslationPending = false;
        }
        if (swap) {
          this.changeLangTranslationPending = false;
          this.changeSecondLangTranslationPending = false;
        }
      }));
  }

  private initEvents() {
    if (this._subscriptions?.length) {
      this._subscriptions.forEach(sub => {
        if (sub) {
          sub.unsubscribe();
        }
      });
      this._subscriptions = [];
    }
    this._subscriptions.push(this._roomService.getStatus$()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res === TranslateStatus.CANCEL) {
          this.currentStatus.set(TranslateStatus.READY);
          this.audioPlayerStatus.set(AudioPlayerStatus.READY);
          return;
        }
        this.currentStatus.set(res);
      }));
    this._subscriptions.push(this._roomService.getRoomEvents$()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res.action === RoomAction.SUMMARY_DELETED) {
          this.text?.filter(msg => {
            return (msg.summaryData?.shareCode === res.shareCode || msg.protocolData?.shareCode === res.shareCode || msg.transcriptData?.shareCode === res.shareCode);
          }).forEach(msg => msg.archived = true);
        }
      }));
    this._subscriptions.push(this._audioService.getAudioPlayerStatus$()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.audioPlayerStatus.set(res);
        console.log('[Audio Service Status] START');
        if (this.audioPlayerStatus() === AudioPlayerStatus.READY && this.currentStatus() === TranslateStatus.SPEECH && this.recorderStatus() === RecorderStatus.READY) {
          this.currentStatus.set(TranslateStatus.READY);
        } else if (this.audioPlayerStatus() === AudioPlayerStatus.READY && this.recorderStatus() === RecorderStatus.READY) {
          this.currentStatus.set(TranslateStatus.READY);
        } else if (this.audioPlayerStatus() === AudioPlayerStatus.ERROR) {
          this.currentStatus.set(TranslateStatus.READY);
          this.lastTime = 0;
          this.readLanguage = '';
        } else if (this.audioPlayerStatus() === AudioPlayerStatus.STOP) {
          this.currentStatus.set(TranslateStatus.READY);
          this.lastTime = 0;
          this.readLanguage = '';
        }
        console.log('[Audio Service Status] END');
      }));
    this._subscriptions.push(this._roomService.getTranscriptText$()
      .pipe(untilDestroyed(this))
      .subscribe(chatMessage => {
        switch (chatMessage.type) {
          case ChatMessageType.MESSAGE:
            this.showChatMessage(chatMessage);
            this.removeSpeakingUser(+chatMessage.userId);
            if (this.recorderStatus() === RecorderStatus.RECORDING) {
              return;
            }
            if (this.me().preferences?.messageTts === MessageTts.AUTO || this._roomService.mode() === RoomMode.MULTI) {
              if (!chatMessage?.photo) {
                this.playMessage(chatMessage);
              }
            }
            break;
          case ChatMessageType.USER_JOIN:
          case ChatMessageType.SUMMARY:
          case ChatMessageType.PROTOCOL:
          case ChatMessageType.TRANSCRIPT:
          case ChatMessageType.USER_LEFT:
            this.showUserJoinChatMessage(chatMessage);
        }
      }));
    this._subscriptions.push(this._roomService.getUserTranscriptText$()
      .pipe(untilDestroyed(this))
      .subscribe(chatMessage => {
        switch (chatMessage.type) {
          case ChatMessageType.VOICE_TRANSCRIPTION:
            this.removeSpeakingUser(+chatMessage.userId);
            this.previewTranscribedMessage(chatMessage);
            break;
          case ChatMessageType.INSERT_TEXT:
            this.insertTranscribedMessage(chatMessage);
            break;
          case ChatMessageType.IMPROVE_TEXT:
            this.showImprovedText(chatMessage);
            break;
        }
      }));
    this._subscriptions.push(this._roomService.getUserEvents$()
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.roomUserAction = user;
        if (user.action === RoomAction.SPEAKING) {
          if (this.nowSpeaking.findIndex(s => s.id === user.roomUser!.id) < 0) {
            this.nowSpeaking.push(<RoomUser>user.roomUser);
            this.generateSpeakingText();
          }
        } else if (user.action === RoomAction.TIME_LEFT) {
          if (this._roomService.creator) {
            this.me().duration = user.duration;
            if (<number>this.me().duration < 0) {
              this.me().duration = 0;
            }
            this._store.dispatch(new SaveUserAction(this.me()));
          }
          this._roomService.room.timeLeft = user.duration;
          if (this._roomService.room.timeLeft <= 0) {
            this._roomService.room.timeLeft = 0;
            this.showErrorPermanent(ErrorCodes.NO_MINUTES_LEFT, 'error.translation.minutes.expired');
          }
        } else if (user.action === RoomAction.STOP_SPEAKING) {
          this.removeSpeakingUser(<number>user.userId);
          this.generateSpeakingText();
        } else if (user.action === RoomAction.JOINED) {
          this._roomService.addUser(<RoomUser>user.roomUser);
          if (this.me().id !== user.roomUser!.id) {
            this._noty.success('message.user.joined', { name: user.roomUser!.name });
          }
          this.scrollToBottom();
        } else if (user.action === RoomAction.LEFT) {
          this._roomService.removeUser(<RoomUser>user.roomUser);
          if (this.me().id !== user.roomUser!.id) {
            this._noty.success('message.user.left.chat', { name: user.roomUser!.name });
          }
        } else if (user.action === RoomAction.USER_BLOCKED) {
          this._roomService.removeUser(<RoomUser>user.roomUser);
          this.removeSpeakingUser(user.roomUser!.id);
          if (this.me().id === user.roomUser!.id) {
            this._noty.success('message.you.blocked');
            this._router.navigate([ '/' ]);
          } else {
            this._noty.success('message.user.blocked', { name: user.roomUser!.name });
          }
          return;
        } else if (user.action === RoomAction.MUTE) {
          if (user.roomUser!.id === this.me().id) {
            this._noty.success('message.speaker.disabled');
            this._audioService.mute();
            this.volumeDisabled = true;
          }
        } else if (user.action === RoomAction.UNMUTE) {
          if (user.roomUser!.id === this.me().id) {
            this._noty.success('message.speaker.enabled');
            this._audioService.unmute();
            this.volumeDisabled = false;
          }
        } else if (user.action === RoomAction.TRANSLATE && this.muted) {
          this.currentStatus.set(TranslateStatus.READY);
          this.audioPlayerStatus.set(AudioPlayerStatus.READY);
        } else if (user.action === RoomAction.CLOSED_BY_HOST) {
          if (user.roomUser!.id !== this.me().id) {
            this._noty.success('message.chat.closed');
            this._router.navigate([ '/', Pages.JOIN_ROOM, this._roomService.room.roomId ]);
          }
        }
      }));
    this._subscriptions.push(this._roomService.myErrorEvents$() // /topic/user/{id}/errors
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res.errorCode === ErrorCodes.NO_MINUTES_LEFT) {
          this.me().duration = 0;
          this._store.dispatch(new SaveUserAction(this.me()));
          this.showError(res.errorCode, res.message);
          setTimeout(() => {
            this.commonError = new ErrorObject(-1, '');
          }, 3000);
          this.currentStatus.set(TranslateStatus.READY);
          this.audioPlayerStatus.set(AudioPlayerStatus.READY);
        } else if (res.errorCode === ErrorCodes.RECOGNITION_ERROR) {
          //
        } else {
          this.showError(res.errorCode, res.message);
        }
      }));
    this._subscriptions.push(this._roomService.getMyEvents$()
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        if (user.action === RoomAction.USER_WAITING || user.action === RoomAction.USER_WAITING_LEFT) {
          this._roomService.waitingUsers = user.users!;
          this.waitingUsers = this._roomService.waitingUsers;
        } else if (user.action === RoomAction.INVITE_APPROVED) {
          // this.joinRoom(user.room.roomId);
          // TODO
        } else if (user.action === RoomAction.INVITE_BLOCKED) {
          this.showError(ErrorCodes.INVITE_BLOCKED, 'you.blocked.by.host');
        } else if (user.action === RoomAction.AWAY_MESSAGES) {
          let msgs = user.messages.reverse();
          msgs = msgs.filter(value => {
            return this.text.findIndex(t => t.id !== value.id);
          });
          this.text = this.text.concat(msgs);
          this.hasMessages = this.text.findIndex(m => m.type === ChatMessageType.MESSAGE) >= 0;
          this.scrollToBottom();
        }
      }));
    this._subscriptions.push(this._roomService.getChatErrors$()
      .pipe(untilDestroyed(this))
      .subscribe(text => {
        this.showChatErrorMessage(text);
      }));
  }

  private generateSpeakingText() {
    let obs;
    if (this.nowSpeaking.length <= 2) {
      obs = this._translateService.selectTranslate('label.speaking', { names: this.nowSpeaking.map(u => u.firstName).join(', ') });
    } else {
      obs = this._translateService.selectTranslate('label.speaking', { names: this.nowSpeaking.length });
    }
    obs.subscribe(names => {
      this.speakingText = names;
    });
  }

  private removeSpeakingUser(userId: number) {
    const user = this.nowSpeaking.findIndex(s => s.id === +userId);
    if (user >= 0) {
      this.nowSpeaking.splice(user, 1);
    }
  }

  private playMessage(textObj: ChatMessageDto) {
    const langCode = this.me().language;
    if (this.currentStatus() === TranslateStatus.RECORDING) {
      return;
    }
    if (textObj?.originalLang !== langCode || this._roomService.mode() === RoomMode.SINGLE) {
      this.readLanguage = langCode;
      if (this._roomService.mode() === RoomMode.SINGLE) {
        this.readLanguage = <string>(textObj?.originalLang === langCode ? this.me().secondLanguage : langCode);
      }
      this.lastTime = textObj.time!;
      if (this._audioService.play(textObj.time!, this.readLanguage, this._roomService.room.roomId)) {
        this.currentStatus.set(TranslateStatus.SPEECH);
      }
    } else {
      console.log('[playMessage]', this.currentStatus);
      this.currentStatus.set(TranslateStatus.READY);
    }
  }

  private afterJoin(res: Room): void {
    this.waitingUsers = res.waitingUsers!;
    this._roomService.init(res.roomId, res.users!, res.waitingUsers!, res.creator);
    this.creator = this._roomService.creator;
    if (!this.me().duration && !this._roomService.room.timeLeft) {
      this.showErrorPermanent(ErrorCodes.NO_MINUTES_LEFT, 'error.translation.minutes.expired');
    }
  }

  private loadMessages(roomId: string) {
    this._roomService.lastMessages(roomId, this._paging.pageSize, (this._paging.currentPage! - 1) * this._paging.pageSize!)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: res => {
          this.text = res.list;
          this.hasMessages = this.text.findIndex(m => m.type === ChatMessageType.MESSAGE) >= 0;
          this.scrollToBottom(100, () => {
            this.loading = false;
          });
        },
        error: () => {
          this.loading = false;
        }
      });
  }

  private loadLastMessage(roomId: string) {
    this._roomService.lastMessage(roomId)
      .pipe(untilDestroyed(this), finalize(() => this.loading = false))
      .subscribe(res => {
        this.singleText = res;
        this.dispatchSingleMessage(this.singleText);
      });
  }

  private dispatchSingleMessage(message: ChatMessageDto): void {
    const state = this.chatState();
    if (!message?.id) {
      state.top.language = this.me().secondLanguage!;
      state.bottom.language = this.me().language;
      state.bottom.nativeLanguage = true;
      this._store.dispatch(new SaveChatAction(state));
      return;
    }
    state.top.form.setValue(message.szdMessages![ state.top.language! ] || '');
    if (!state.top.form.value && message.originalLang === state.top.language) {
      state.top.form.setValue(message.originalText);
    }
    state.top.photo = message.photo;
    state.top.photoMode = !!message.photo;
    state.top.photoDesc = !!message.photoDesc;
    if (state.top.photoMode && state.top.showOriginalText) {
      state.top.showOriginalText = false;
    }
    state.top.editor.blur();
    state.bottom.form.setValue(message.szdMessages[ state.bottom.language ] || '');
    if (!state.bottom.form.value && message.originalLang === state.bottom.language) {
      state.bottom.form.setValue(message.originalText);
    }
    state.bottom.editor.blur();
    this._store.dispatch(new SaveChatAction(state));
  }

  private showChatErrorMessage(text: AIChatError) {
    if (text.errorCode === ErrorCodes.NO_MINUTES_LEFT) {
      return;
    }
    this.showError(text.errorCode, text.message);
    // this.error = { message: text.message, errorCode: text.errorCode, field: null };
    this.currentStatus.set(TranslateStatus.READY);
    this.audioPlayerStatus.set(AudioPlayerStatus.READY);
  }

  private showChatMessage(text: ChatMessageDto) {
    if (this._roomService.mode() === RoomMode.SINGLE) {
      this.singleText = text;
      this.dispatchSingleMessage(this.singleText);
    } else {
      this.text = this.text.concat(text);
      this.hasMessages = this.text.findIndex(m => m.type === ChatMessageType.MESSAGE) >= 0;
    }
    this.lastTime = text.time;
    this.scrollToBottom();
  }

  private previewTranscribedMessage(text: ChatMessageDto) {
    if (this._roomService.mode() === RoomMode.SINGLE) {
      let chat;
      if (this.chatState().top.language === text.originalLang) {
        chat = this.chatState().top;
      } else if (this.chatState().bottom.language === text.originalLang) {
        chat = this.chatState().bottom;
      } else {
        return;
      }
      chat.editMode = true;
      chat.prevText = chat.form.value;
      chat.form.setValue(text.originalText);
      chat.editor.focus();
      chat.caretPos = chat.editor.getLength();
      chat.editor.setSelection(chat.caretPos, 0);
      chat.contentChanged = true;
      this.dispatchChatWindow(chat);
    } else {
      this.messageCtrl.setValue(text.originalText);
    }
  }

  private insertTranscribedMessage(text: ChatMessageDto) {
    const chat: ChatWindow = this.chatState().top.editMode ? this.chatState().top : this.chatState().bottom;
    const textToInsert = ' ' + text.originalText + ' ';
    const caretPos = chat.editor.getSelection(true).index;
    chat.editor.insertText(caretPos, textToInsert);
    chat.editor.setSelection(caretPos + textToInsert.length);
    chat.contentChanged = true;
    this.dispatchChatWindow(chat);
  }

  private insertMessage(chat: ChatWindow, text: string) {
    const caretPos = chat.editor.getSelection(true).index;
    chat.editor.insertText(caretPos, text);
    chat.contentChanged = true;
    this.dispatchChatWindow(chat);
  }

  private showImprovedText(text: ChatMessageDto) {
    let chat: ChatWindow = this.chatState().top.editMode ? this.chatState().top : this.chatState().bottom;
    chat.form.setValue(text.originalText);
    chat.contentChanged = true;
    this.dispatchChatWindow(chat);
    this.aiImprovePending.set(false);
  }

  private showUserJoinChatMessage(text: ChatMessageDto) {
    if (this._roomService.mode() === RoomMode.MULTI) {
      this.text = this.text.concat(text);
    }
    this.scrollToBottom();
  }

  private scrollToBottom(timeout = 40, callback?: any): void {
    setTimeout(() => {
      if (this.scrollbar) {
        this.scrollbar.scrollToBottom().then();
        if (callback) {
          callback();
        }
      }
    }, timeout);
  }

  protected swapLanguageLoader = false;

  swapLanguages() {
    if (!this.me().language || !this.me().secondLanguage) {
      return;
    }
    this.swapLanguageLoader = true;
    this.saveMyLangs(null, { firstLang: this.me().language, secondLang: this.me().secondLanguage }, true);
  }

  clearPanelMessage(chat: ChatWindow) {
    if (!chat.prevText) {
      chat.prevText = chat.form.value;
    }
    chat.form.reset();
  }

  togglePhotoButton() {
    if (!this.messageCtrl.value) {
      this.photoButton.set(true);
    } else {
      this.photoButton.set(false);
    }
  }

  aiImprovePending = signal(false);

  aiImprove(chat: ChatWindow): void {
    if (!chat.editor.getText()?.trim()) {
      return;
    }
    this.aiImprovePending.set(true);
    this._roomService.improveMessage(chat.editor.getText().trim(), chat.language);
  }

  undoText(chat: ChatWindow) {
    // @ts-ignore
    chat.editor.history.undo();
  }

  redoText(chat: ChatWindow) {
    // @ts-ignore
    chat.editor.history.redo();
  }

  initEditor(chat: ChatWindow, editor: QuillModules) {
    chat.editor = editor;
  }
}
