import { Component, Input } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { timeOutline } from 'ionicons/icons';

@Component({
  selector: 'app-tariff-title',
  standalone: true,
  imports: [
    TranslocoPipe,
    IonicModule
  ],
  templateUrl: './tariff-title.component.html',
  styleUrl: './tariff-title.component.scss'
})
export class TariffTitleComponent {
  @Input() tariffId: string;

  constructor() {
    addIcons({ timeOutline });
  }
}
