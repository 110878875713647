import { Component, inject, OnInit, Signal, ViewChild } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { DialogService } from '@service/dialog.service';
import { AuthService } from '@service/auth/auth.service';
import { ProfileMenuEnum, RoomUser, UserRole } from '@shared/transport.interface';
import { UserState } from '@shared/store/user/user-state.service';
import { SaveUserAction } from '@shared/store/user/user.actions';
import { Pages } from '@app/pages';
import { IonicModule } from '@ionic/angular';
import { InitialsAvatarComponent } from '../../ui/initials-avatar/initials-avatar.component';
import { environment } from '@env/environment';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SecurityComponent } from './security/security.component';
import { PreferenceComponent } from './preference/preference.component';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { addIcons } from 'ionicons';
import {
  arrowBackOutline,
  cardOutline,
  lockClosedOutline,
  notificationsOutline,
  optionsOutline,
  personCircleOutline,
  personRemoveOutline,
  shareSocialOutline
} from 'ionicons/icons';
import { ModalController } from '@ionic/angular/standalone';
import { RoomService } from "@service/room.service";
import { DeleteAccountComponent } from '@dialog/profile/delete-account/delete-account.component';
import { AppVersionService } from '@service/app-version.service';

@UntilDestroy()
@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    TranslocoPipe,
    IonicModule,
    InitialsAvatarComponent,
    EditProfileComponent,
    InvoicesComponent,
    NotificationsComponent,
    SecurityComponent,
    PreferenceComponent,
    MatStepper,
    MatStep,
    DeleteAccountComponent,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
  private _modalCtrl = inject(ModalController);
  @ViewChild(MatStepper) stepper: MatStepper;

  private readonly _store = inject(Store);
  private readonly _dialogService = inject(DialogService);
  private readonly _authService = inject(AuthService);
  private readonly _router = inject(Router);
  private readonly _roomService = inject(RoomService);
  private readonly _versionService = inject(AppVersionService);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  protected menu = [
    new ProfileMenuItem(1, ProfileMenuEnum.MY_ACCOUNT, 'person-circle-outline', [ UserRole.USER, UserRole.COMPANY_USER, UserRole.COMPANY_HOST, UserRole.COMPANY_ADMIN ]),
    // new ProfileMenuItem(2, ProfileMenuEnum.ADDRESS, 'fa-regular fa-building', [ UserRole.USER, UserRole.COMPANY_USER, UserRole.COMPANY_HOST, UserRole.COMPANY_ADMIN ]),
    new ProfileMenuItem(3, ProfileMenuEnum.INVOICES, 'card-outline', [ UserRole.USER, UserRole.COMPANY_USER, UserRole.COMPANY_HOST, UserRole.COMPANY_ADMIN ]),
    new ProfileMenuItem(4, ProfileMenuEnum.NOTIFICATIONS, 'notifications-outline', [ UserRole.USER, UserRole.COMPANY_USER, UserRole.COMPANY_HOST, UserRole.COMPANY_ADMIN ]),
    new ProfileMenuItem(5, ProfileMenuEnum.SECURITY, 'lock-closed-outline', [ UserRole.USER, UserRole.COMPANY_USER, UserRole.COMPANY_HOST, UserRole.COMPANY_ADMIN ]),
    new ProfileMenuItem(6, ProfileMenuEnum.PREFERENCE, 'options-outline', [ UserRole.USER, UserRole.COMPANY_USER, UserRole.COMPANY_HOST, UserRole.COMPANY_ADMIN ]),
    new ProfileMenuItem(7, ProfileMenuEnum.SHARE_APP, 'share-social-outline', [ UserRole.USER, UserRole.COMPANY_USER, UserRole.COMPANY_HOST, UserRole.COMPANY_ADMIN ]),
    new ProfileMenuItem(8, ProfileMenuEnum.DELETE_ACCOUNT, 'person-remove-outline', [ UserRole.USER, UserRole.COMPANY_USER, UserRole.COMPANY_HOST, UserRole.COMPANY_ADMIN ],
      'text-red-600'),
  ];
  pending = false;
  appVersion = environment.appVersion;

  constructor() {
    addIcons({ personCircleOutline, cardOutline, notificationsOutline, lockClosedOutline, optionsOutline, arrowBackOutline, personRemoveOutline, shareSocialOutline });
  }

  ngOnInit(): void {
    if (this.me().deleteTime) {
      this.menu.pop();
    }
    this.appVersion = this._versionService.getCurrentVersion();
  }

  menuSelected(item: ProfileMenuItem): void {
    if (item.id === ProfileMenuEnum.SHARE_APP) {
      this._dialogService.shareAppQrCode(this.me().ref);
      return;
    }
    this.stepper.selectedIndex = item.num;
  }

  logout(): void {
    this.pending = true;
    this._authService
      .signOut()
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(() => {
        this._roomService.unsubscribeFromAll();
        this._router.navigate([ '/', Pages.LOGIN ]);
        this._dialogService.closeAll();
        this._store.dispatch(new SaveUserAction(null));
      });
  }

  closeModal() {
    this._modalCtrl.dismiss();
  }

  protected readonly environment = environment;
}

export class ProfileMenuItem {
  id: ProfileMenuEnum;
  icon: string;
  num: number;
  roles: UserRole[];
  klass: string;

  constructor(num: number, id: ProfileMenuEnum, icon: string, roles: UserRole[], klass?: string) {
    this.id = id;
    this.num = num;
    this.icon = icon;
    this.roles = roles;
    this.klass = klass;
  }
}
