<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>{{ 'title.blocked' | transloco }}</ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  <div class="flex flex-col w-full flex-auto min-w-0 overflow-hidden">
    <ion-content>
      <div class="flex flex-col items-center w-full flex-auto overflow-y-auto overflow-x-hidden relative bg-white">
        <div class="flex flex-col w-full p-5 flex-auto overflow-y-auto">
          <div class="flex flex-col w-full flex-auto">
            <div class="text-base mb-5">{{ 'text.blocked' | transloco }}</div>
            @for (user of users; track user.id; let ind = $index) {
              <div class="py-2.5 border-t border-t-gray-300 flex justify-between items-center"
                   [formGroup]="formArray.at(ind)">
                <app-user-card [me]="user"></app-user-card>
                <div>
                  <app-checkbox formControlName="checked"></app-checkbox>
                </div>
              </div>
            }
          </div>
          @if (users?.length) {
            <div class="pt-5">
              <button class="button w-full" [class.loading]="pending" [disabled]="pending"
                      (click)="unblock()">{{ 'button.unblock' | transloco }}
              </button>
            </div>
          }
        </div>
      </div>
    </ion-content>
  </div>
</div>
