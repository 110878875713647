import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import SiriWave from 'siriwave';

@Component({
  selector: 'app-wave-animation',
  standalone: true,
  imports: [],
  templateUrl: './wave-animation.component.html',
  styleUrl: './wave-animation.component.scss'
})
export class WaveAnimationComponent implements OnInit, OnDestroy {
  @ViewChild('wave', { static: true }) waveEl: ElementRef;
  wave: SiriWave;

  ngOnInit(): void {
    this.wave = new SiriWave({
      style: 'ios9',
      amplitude: 2.5,
      cover: true,
      autostart: true,
      curveDefinition: [
        {
          attenuation: -2,
          lineWidth: 1,
          opacity: 0.1,
          color: '225, 0, 0'
        },
        {
          attenuation: -6,
          lineWidth: 1,
          opacity: 0.2,
          color: '225, 0, 0'
        },
        {
          attenuation: 4,
          lineWidth: 1,
          opacity: 0.4,
          color: '225, 0, 0'
        },
        {
          attenuation: 2,
          lineWidth: 1,
          opacity: 0.6,
          color: '225, 0, 0'
        },
        {
          attenuation: 1,
          lineWidth: 1.5,
          opacity: 1,
          color: '225, 86, 0'
        } ],
      container: this.waveEl.nativeElement,
      width: this.waveEl.nativeElement.clientWidth
    });
  }

  ngOnDestroy(): void {
    if (this.wave) {
      try {
        this.wave.dispose();
      } catch (e) {
        //
      }
    }
  }

}
