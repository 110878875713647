import { Component, ElementRef, EventEmitter, inject, OnInit, Output, ViewChild } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { StorageService } from '@service/storage.service';

@Component({
  selector: 'app-tour',
  standalone: true,
  imports: [
    TranslocoPipe
  ],
  templateUrl: './tour.component.html',
  styleUrl: './tour.component.scss'
})
export class TourComponent implements OnInit {
  private _storageService = inject(StorageService);

  @Output() completeTour: EventEmitter<void> = new EventEmitter();
  @ViewChild('hole', { static: true }) hole: ElementRef;
  steps = [];
  currentStep = 1;
  currentStepInfo: TourStep;

  constructor() {
    this.steps.push(new TourStep(`tour-step-1`, `tour.step.1`, `tour.step.1.desc`));
    this.steps.push(new TourStep(`tour-step-2`, `tour.step.2`, `tour.step.2.desc`));
    this.steps.push(new TourStep(`tour-step-5`, `tour.step.5`, `tour.step.5.desc`));
    this.steps.push(new TourStep(`tour-step-4`, `tour.step.4`, `tour.step.4.desc`));
    this.steps.push(new TourStep(`tour-step-7`, `tour.step.7`, `tour.step.7.desc`));
    this.steps.push(new TourStep(`tour-step-8`, `tour.step.8`, `tour.step.8.desc`));
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.setHighlight(this.currentStep);
    }, 100);
  }

  private setHighlight(stepNum: number): void {
    this.currentStepInfo = this.steps[ stepNum - 1 ] as TourStep;
    const stepEl = document.getElementById(this.currentStepInfo.element) as HTMLElement;
    if (!stepEl) {
      return;
    }
    const padding = 20;
    this.hole.nativeElement.style.top = (stepEl.getBoundingClientRect().top - padding / 2) + 'px';
    this.hole.nativeElement.style.left = (stepEl.getBoundingClientRect().left - padding / 2) + 'px';
    this.hole.nativeElement.style.width = (stepEl.offsetWidth + padding) + 'px';
    this.hole.nativeElement.style.height = (stepEl.offsetHeight + padding) + 'px';
  }

  nextStep(): void {
    if (this.currentStep >= this.steps.length) {
      return;
    }
    this.currentStep++;
    this.setHighlight(this.currentStep);
  }

  prevStep(): void {
    if (this.currentStep <= 1) {
      return;
    }
    this.currentStep--;
    this.setHighlight(this.currentStep);
  }

  closeTour(): void {
    const date = new Date();
    date.setFullYear(2099);
    this._storageService.set('skipTour', 'true');
    this.completeTour.emit();
  }
}

export class TourStep {
  private _title: string;
  private _description: string;
  private _element: string;

  constructor(element: string, title: string, description: string) {
    this._title = title;
    this._description = description;
    this._element = element;
  }

  get title(): string {
    return this._title;
  }

  get description(): string {
    return this._description;
  }

  get element(): string {
    return this._element;
  }
}
