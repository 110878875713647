import { Component, inject } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IonicModule } from '@ionic/angular';
import { FuseAlertComponent } from '@comp/ui/alert';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '@service/auth/auth.service';
import { finalize } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-delete-account',
  standalone: true,
  imports: [
    TranslocoPipe,
    IonicModule,
    FuseAlertComponent,
    ReactiveFormsModule
  ],
  templateUrl: './delete-account.component.html',
  styleUrl: './delete-account.component.scss'
})
export class DeleteAccountComponent {
  protected pending = false;

  private readonly _authService = inject(AuthService);

  protected sent = false;
  protected commonError = null;

  constructor() {
  }

  confirmAccountDeletion() {
    this.pending = true;
    this.commonError = null;
    this._authService.deleteAccount()
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe({
        next: () => {
          this.sent = true;
        },
        error: err => {
          this.commonError = err;
        }
      });
  }
}
