<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>
      {{ page?.content[0]?.titles[0]?.text }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closePage()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  @if (loading) {
    <app-loader></app-loader>
  } @else {
    <div class="flex flex-col w-full flex-auto min-w-0 overflow-hidden">
      <ion-content>
        <div class="p-5 w-full bg-white">
          <div class="common-text" [innerHTML]="page?.content[0]?.texts[0]?.text">
          </div>
        </div>
      </ion-content>
    </div>
  }
</div>
