import { inject, Injectable, Signal } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { HttpClientService } from '../http-client/http-client.service';
import { CommonLanguage } from '../transport.interface';
import { ApiUrl } from '../api-url';
import { LanguagesState } from '@shared/store/languages/languages-state.service';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  private readonly _store = inject(Store);

  langsSignal: Signal<CommonLanguage[]> = this._store.selectSignal(LanguagesState.getLanguages);

  constructor(private _http: HttpClientService, private _translate: TranslocoService) {
  }

  langsFroAI(): Observable<CommonLanguage[]> {
    if (this.langsSignal()?.length) {
      return of(this.langsSignal());
    }
    return this._http.doGet(ApiUrl.AI_LANGS_LIST);
  }

  findLang(lang: string): CommonLanguage {
    return this.langsSignal()?.find((lang) => lang === lang);
  }

  getBrowserLang(): string {
    if (navigator.language) {
      return navigator.language.split('-')[ 0 ];
    }
    return 'en';
  }

  checkForRtl(lang: string, reload = false) {
    if ([ 'ar', 'he', 'fa', 'ur' ].indexOf(lang) >= 0) {
      document.dir = 'rtl';
    } else {
      document.dir = 'ltr';
    }
    if (reload) {
      window.location.reload();
    }
  }

  isRtl(lang: string) {
    return [ 'ar', 'he', 'fa', 'ur' ].indexOf(lang) >= 0;
  }

  isActiveLangRtl(): boolean {
    return this.isRtl(this._translate.getActiveLang());
  }
}
