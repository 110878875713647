import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { Html5Qrcode } from "html5-qrcode";
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { TranslocoPipe } from '@ngneat/transloco';
import { LoaderComponent } from '@shared/component/loader/loader.component';
import { Pages } from '@app/pages';
import { IonIcon } from '@ionic/angular/standalone';

@Component({
  selector: 'app-qr-code-scanner-dialog',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    TranslocoPipe,
    LoaderComponent,
    IonIcon
  ],
  templateUrl: './qr-code-scanner-dialog.component.html',
  styleUrl: './qr-code-scanner-dialog.component.scss'
})
export class QrCodeScannerDialogComponent implements AfterViewInit, OnDestroy {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  loading = false;
  html5QrCode: Html5Qrcode;

  constructor() {
  }

  onScanSuccess(decodedText: string): void {
    if (decodedText && decodedText.indexOf(Pages.JOIN_ROOM) >= 0) {
      decodedText = decodedText.split(`${ Pages.JOIN_ROOM }/`)[ 1 ];
    }
    this.ngOnDestroy();
    this.closeModal(decodedText);
  }

  onScanFailure(error) {
    console.warn(`Code scan error = ${ error }`);
  }

  ngAfterViewInit(): void {
    this.startScanning();
  }

  startScanning(): void {
    this.loading = true;
    this.html5QrCode = new Html5Qrcode(/* element id */ "reader");
    // const height = document.body.offsetHeight;
    // const width = document.body.offsetWidth;
    // const minWidth = Math.min(height, width);
    this.html5QrCode.start(
      { facingMode: "environment" },
      {
        fps: 10,
        qrbox: 300,
        aspectRatio: 1,
      },
      (decodedText: string) => {
        this.onScanSuccess(decodedText);
      },
      (errorMessage) => {
        console.log(errorMessage);
      })
      .then(() => this.loading = false)
      .catch((err) => {
        // Start failed, handle it.
      });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.html5QrCode.isScanning) {
      this.html5QrCode
        .stop()
        .then(() => this.html5QrCode.clear());
    } else {
      this.html5QrCode.clear();
    }
  }

}
