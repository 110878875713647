import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { DialogService } from '../../service/dialog.service';

@Component({
  selector: 'app-archive-chat-message',
  standalone: true,
  imports: [
    TranslocoPipe
  ],
  templateUrl: './archive-chat-message.component.html',
  styleUrl: './archive-chat-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArchiveChatMessageComponent {
  private _dialogService = inject(DialogService);

  @Input() roomId: string;

  exitRoom(): void {
    this._dialogService.exitRoom(this.roomId)
      .subscribe(() => {
        this._dialogService.closeAll();
      });
  }
}
