import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';
import { provideTransloco } from '@ngneat/transloco';
import { provideStore, withNgxsDevelopmentOptions } from '@ngxs/store';
import { appRoutes } from './app.routes';
import { TranslocoHttpLoader } from '@shared/utils/transloco.http-loader';
import { LanguagesState } from '@shared/store/languages/languages-state.service';
import { UserState } from '@shared/store/user/user-state.service';
import { SiteInfoState } from '@shared/store/site/site-state.service';
import { ChatState } from '@shared/store/chat/chat-state.service';
import { provideAuth } from '@shared/service/auth/auth.provider';
import { RxStompService } from '@shared/service/rx-stomp/rx-stomp.service';
import { rxStompServiceFactory } from '@shared/service/rx-stomp/rx-stomp-service-factory';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import Hammer from 'hammerjs';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_DATE_FORMATS, provideNativeDateAdapter } from '@angular/material/core';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { StorageService } from '@service/storage.service';
import { provideQuillConfig } from 'ngx-quill';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

export function appInitializer(storageService: StorageService) {
  return () => storageService.init();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(),
    provideRouter(appRoutes,
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
    ),
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        verticalPosition: 'top',
        horizontalPosition: 'right',
        duration: 500000
      }
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'D',
        },
        display: {
          dateInput: 'DDD',
          monthYearLabel: 'LLL yyyy',
          dateA11yLabel: 'DD',
          monthYearA11yLabel: 'LLLL yyyy',
        },
      },
    },
    provideTransloco({
      config: {
        availableLangs: [ "ar",
          "af",
          "hy",
          "az",
          "be",
          "bs",
          "bg",
          "hr",
          "cs",
          "et",
          "gl",
          "el",
          "he",
          "hu",
          "id",
          "kn",
          "kk",
          "lv",
          "lt",
          "mk",
          "ms",
          "mr",
          "mi",
          "ne",
          "fa",
          "sr",
          "sk",
          "sl",
          "sw",
          "tl",
          "ta",
          "th",
          "uk",
          "ur",
          "vi",
          "zh",
          "da",
          "en",
          "fi",
          "fr",
          "de",
          "hi",
          "is",
          "it",
          "ja",
          "ko",
          "no",
          "pl",
          "pt",
          "ru",
          "sv",
          "tr",
          "cy" ],
        defaultLang: 'en',
        fallbackLang: 'en',
        reRenderOnLangChange: true,
        missingHandler: {
          useFallbackTranslation: true
        },
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader
    }),
    provideStore([ LanguagesState, UserState, SiteInfoState, ChatState ],
      withNgxsDevelopmentOptions({
        warnOnUnhandledActions: true // <-- set this flag to 'true' will warn for any unhandled actions
      })),
    provideMomentDateAdapter(MY_FORMATS),
    provideNativeDateAdapter(),
    // Fuse
    provideAuth(),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [ StorageService ]
    },
    importProvidersFrom(IonicStorageModule.forRoot({
      driverOrder: [ Drivers.IndexedDB, Drivers.LocalStorage ],
      dbKey: '_finexword'
    })),
    // provideIcons(),
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
    },
    importProvidersFrom(HammerModule),
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig, deps: [] },
    provideQuillConfig({
      modules: {
        syntax: false,
        toolbar: false,
        formats: false
      }
    })
  ],
};
