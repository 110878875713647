<div class="walkthrough-background walkthrough-active flex justify-center">
  <div class="flex flex-col justify-center absolute bottom-[150px] z-10 text-white px-5 w-full">
    <div class="w-full flex items-center justify-between">
      <div class="title-1 text-3xl font-bold">{{ currentStepInfo?.title | transloco }}</div>
      <div class="title-1 text-3xl font-bold">{{ currentStep }}/{{ steps.length }}</div>
    </div>
    <div class="mt-2.5 w-full text-left">
      <div [innerHTML]="currentStepInfo?.description | transloco"></div>
    </div>
    <div class="h-[1px] w-full border-t-white border-t my-5"></div>
    <div class="flex justify-between">
      <button class="button button-m transparent white" (click)="closeTour()">{{ 'button.skip'|  transloco }}</button>
      <div class="flex">
        @if (currentStep > 1) {
          <button class="button button-m mr-1 stroked bordered white" (click)="prevStep()">{{ 'button.back'|  transloco }}</button>
        }
        @if (currentStep === steps.length) {
          <button class="button button-m ml-1" (click)="closeTour()">{{ 'button.close'|  transloco }}</button>
        } @else {
          <button class="button button-m ml-1" (click)="nextStep()">{{ 'button.next'|  transloco }}</button>
        }
      </div>
    </div>
  </div>
  <div class="walkthrough-hole" #hole></div>
</div>
