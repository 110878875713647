<div class="overflow-hidden w-full flex flex-col bg-white p-5">
  <div class="flex flex-col">
    <div class="flex justify-between items-center">
      <div class="title text-4xl">{{ 'title.join.conversation' | transloco }}</div>
      <button mat-icon-button (click)="closeModal()">
        <ion-icon name="close-outline" class="text-4xl cursor-pointer"></ion-icon>
      </button>
    </div>
    <div class="mt-2">
      <span [innerHTML]="'text.join.conversation' | transloco"></span>
      @if (notFound) {
        <app-alert type="warning" class="mt-4" [appearance]="'fill'">{{ 'error.room.not_found' | transloco }}</app-alert>
      }
      <div class="mt-6 w-full flex justify-center">
        <ngx-otp-input [options]="otpOptions" (otpComplete)="enableSearchButton()" (otpChange)="changeRoomId($event)"></ngx-otp-input>
      </div>
    </div>
    <div class="mt-8">
      <button class="button w-full" (click)="joinRoom()" [disabled]="pending || !valid" [class.loading]="pending">{{ 'button.search' | transloco }}</button>
      <button class="button stroked w-full" (click)="closeModal()" [disabled]="pending">{{ 'button.cancel' | transloco }}</button>
    </div>
  </div>
</div>
