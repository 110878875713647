import { Component, inject, Input, OnInit } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { Tariff, TariffPackage } from '@shared/transport.interface';
import { UserService } from '@service/user.service';
import { IonicModule } from '@ionic/angular';
import { LoaderComponent } from '@comp/loader/loader.component';
import { MinutesPackageCardComponent } from '@comp/minutes-package-card/minutes-package-card.component';
import { ModalController } from '@ionic/angular/standalone';
import { InappPurchasesService } from '@service/inapp-purchases.service';

@UntilDestroy()
@Component({
  selector: 'app-buy-package',
  standalone: true,
  imports: [
    TranslocoPipe,
    IonicModule,
    LoaderComponent,
    MinutesPackageCardComponent,

  ],
  templateUrl: './buy-package.component.html',
  styleUrl: './buy-package.component.scss'
})
export class BuyPackageComponent implements OnInit {
  private readonly _modalCtrl = inject(ModalController);
  private readonly _iapService = inject(InappPurchasesService);
  private readonly _userService = inject(UserService);

  @Input() tariff: Tariff;

  loading = false;
  tariffPackages: TariffPackage[];

  ngOnInit(): void {
    if (this.tariff) {
      this.loading = true;
      this._userService.buyPackagesInfo()
        .pipe(untilDestroyed(this), finalize(() => this.loading = false))
        .subscribe(res => {
          this.tariffPackages = res;
          let products = this._iapService.products() || [];
          this.tariffPackages.forEach(p => {
            const found = products.find(product => product.id === p.productId);
            if (found) {
              p.price = +found.pricing.priceMicros / 1000000;
              p.currency = found.pricing.currency;
            }
          });
        });
    }
  }

  closeModal() {
    this._modalCtrl.dismiss();
  }
}
