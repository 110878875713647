import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from '@service/auth/auth.service';
import { RefreshToken } from '../transport.interface';
import { appComponentRef } from '../app-component';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);

  // Clone the request object
  let newReq = req.clone();

  // Request
  //
  // If the access token didn't expire, add the Authorization header.
  // We won't add the Authorization header if the access token expired.
  // This will force the server to return a "401 Unauthorized" response
  // for the protected API routes which our response interceptor will
  // catch and delete the access token from the local storage while logging
  // the user out from the app.
  return fromPromise(authService.accessToken())
    .pipe(switchMap(token => {
      let needToken = true;
      if (req.url.indexOf('/terms') >= 0 || req.url.indexOf('/privacy-policy') >= 0 || req.url.indexOf('/help') >= 0 || req.url.indexOf('/main-page') >= 0) {
        needToken = false;
      }
      if (token && needToken) {
        let authorization = req.headers.set('Authorization', token);
        authorization = authorization.set('X-Authorization', token);
        newReq = req.clone({
          headers: authorization,
        });
      }
      return next(newReq).pipe(
        catchError((error) => {
          if (error.status === 401 && error.url.indexOf('/v1/user/token/refresh') < 0) {
            return authService.refreshToken().pipe(
              switchMap((newToken: RefreshToken) => {
                let authorization = req.headers.set('Authorization', newToken.accessToken);
                authorization = authorization.set('X-Authorization', newToken.accessToken);
                const authReq = req.clone({
                  headers: authorization,
                });
                return next(authReq);
              }),
              catchError(err => {
                appComponentRef().invalidSessionEvent();
                return throwError(() => error);
              })
            );
          } else if ((error.status === 401 || error.status === 400) && error.url.indexOf('/v1/user/token/refresh') >= 0) {
            appComponentRef().invalidSessionEvent();
            return throwError(() => error);
          }
          return throwError(() => error);
        })
      );
    }));
};

