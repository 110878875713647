import { Component, inject, OnInit, Signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, finalize } from 'rxjs';
import { Store } from '@ngxs/store';
import { IonicModule, Platform } from '@ionic/angular';
import { TranslocoPipe } from '@ngneat/transloco';
import { AuthService } from '@shared/service/auth/auth.service';
import { AuthResult, SiteInfo, UserRole } from '@shared/transport.interface';
import { SiteInfoState } from '@shared/store/site/site-state.service';
import { SaveUserAction } from '@shared/store/user/user.actions';
import { Pages } from '@app/pages';
import { ErrorObject } from '@shared/http-client/error-object.class';
import { GoogleAuthComponent } from '@shared/component/social/google-auth/google-auth.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { HeaderComponent } from '@shared/component/header/header.component';
import { FuseAlertComponent } from '@comp/ui/alert';
import { StorageService } from '@service/storage.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { Keyboard } from '@capacitor/keyboard';
import { UtilsService } from '@service/utils.service';
import { DialogService } from '@service/dialog.service';

@UntilDestroy()
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    RouterLink,
    IonicModule,
    TranslocoPipe,
    GoogleAuthComponent,
    MatFormField,
    MatInput,
    MatLabel,
    HeaderComponent,
    FuseAlertComponent
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  commonError: ErrorObject | null = null;
  pending = false;
  form: FormGroup;
  redirectUrl: string = '';

  private readonly _store = inject(Store);
  private readonly _fb = inject(FormBuilder);
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);
  private readonly _authService = inject(AuthService);
  private readonly _storageService = inject(StorageService);
  private readonly _platform = inject(Platform);
  private readonly _utilsService = inject(UtilsService);
  private readonly _dialogService = inject(DialogService);

  protected _siteInfo: Signal<SiteInfo> = this._store.selectSignal(SiteInfoState.getSiteInfo);

  constructor() {
    this._route.queryParams.pipe(untilDestroyed(this), filter((p: Params) => !!p[ 'redirectUrl' ])).subscribe(res => {
      this.redirectUrl = res[ 'redirectUrl' ];
    });
    this.form = this._fb.group({
      email: [ '', [ Validators.required, Validators.email ] ],
      password: [ '', [ Validators.required ] ],
    });
    this._storageService.logged().then(logged => {
      if (!logged) {
        this._store.dispatch(new SaveUserAction(null));
      }
    });
  }

  ngOnInit(): void {
    this.pending = false;
    if (this._platform.is('mobile') && !this._platform.is('mobileweb')) {
      Keyboard.setAccessoryBarVisible({ isVisible: false });
    }
    SplashScreen.hide().then(() => {
      this._utilsService.statusBar();
    });
  }

  login(): void {
    if (this.form.invalid || this.pending) {
      return;
    }
    this.commonError = null;
    this.pending = true;
    const req = this.form.value;
    this._authService.signIn(req.email, req.password)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe({
        next: (res: AuthResult) => {
          if (res.user.role !== UserRole.TEMP_USER) {
            this._storageService.removeExternalId();
          }
          if (this.redirectUrl) {
            this._router.navigateByUrl(this.redirectUrl);
            return;
          }
          if (res.user.deleteTime) {
            setTimeout(() => {
              this._dialogService.showDeleteAccountReminder();
            }, 500);
          }
          if ([ UserRole.COMPANY_HOST, UserRole.COMPANY_ADMIN ].indexOf(res.user.role!) >= 0) {
            this._router.navigate([ '/', Pages.COMPANY, Pages.PORTAL, Pages.DASHBOARD ]);
          } else {
            this._router.navigate([ '/' ]);
          }
        },
        error: (err: any) => {
          this.commonError = err;
        }
      });
  }

  protected readonly Pages = Pages;
}
