<div class="flex flex-col justify-center text-center chat-highlight-message p-4 italic">
  <div [innerHTML]="'text.empty.chat' | transloco"></div>

  <div class="w-full flex items-center justify-center mt-5">
    <div>
      <button class="button button-sm stroked bordered" (click)="exitRoom()">
        {{'button.archive' | transloco}}
      </button>
    </div>
  </div>
</div>
