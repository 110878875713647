import { Route } from '@angular/router';
import { initialDataResolver, siteInfoDataResolver } from '@app/app.resolvers';
import { AuthGuard } from '@shared/guard/auth.guard';
import { Pages } from '@app/pages';
import { LoginComponent } from '@app/page/login/login.component';
import { DefaultSiteGuard } from '@shared/guard/defaultSiteGuard';
import {ChatComponent} from "@app/page/chat/chat.component";

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: Pages.WELCOME
  },
  // {
  //   path: Pages.HISTORY + '/:roomId/' + Pages.CHAT,
  //   loadComponent: () => import('app/page/messages-history/messages-history.component').then(m => m.MessagesHistoryComponent),
  //   resolve: {
  //     initialData: initialDataResolver,
  //     siteInfo: siteInfoDataResolver
  //   },
  //   canActivate: [ AuthGuard ]
  // },
  {
    path: Pages.PAYMENT_SUCCESS,
    loadComponent: () => import('src/app/page/successful-payment/successful-payment.component').then(m => m.SuccessfulPaymentComponent),
    resolve: {
      initialData: initialDataResolver,
      siteInfo: siteInfoDataResolver
    },
    canActivate: [ AuthGuard ]
  },
  {
    path: Pages.PROMO_CODE + '/:code',
    loadComponent: () => import('src/app/page/use-promo-code/use-promo-code.component').then(m => m.UsePromoCodeComponent),
    resolve: {
      initialData: initialDataResolver,
      siteInfo: siteInfoDataResolver
    },
    canActivate: [ AuthGuard ]
  },
  {
    path: Pages.PAYMENT_FAILED,
    loadComponent: () => import('src/app/page/payment-failed/payment-failed.component').then(m => m.PaymentFailedComponent),
    resolve: {
      initialData: initialDataResolver,
      siteInfo: siteInfoDataResolver
    },
    canActivate: [ AuthGuard ]
  },
  {
    path: Pages.INVITE,
    loadComponent: () => import('src/app/page/invite/invite.component').then(m => m.InviteComponent),
  },
  {
    path: Pages.SUBSCRIPTION_CANCELED,
    loadComponent: () => import('src/app/page/subscription-cancelled/subscription-cancelled.component').then(m => m.SubscriptionCancelledComponent),
    resolve: {
      initialData: initialDataResolver,
      siteInfo: siteInfoDataResolver
    },
    canActivate: [ AuthGuard ]
  },
  {
    path: Pages.REGISTER,
    loadComponent: () => import('src/app/page/register/register.component').then(m => m.RegisterComponent),
    resolve: {
      siteInfo: siteInfoDataResolver
    },
    canActivate: [ DefaultSiteGuard ]
  },
  {
    path: Pages.JOIN_ROOM + '/:roomId',
    // component: JoinRoomComponent,
    loadComponent: () => import('src/app/page/join-room/join-room.component').then(m => m.JoinRoomComponent),
    resolve: {
      initialData: initialDataResolver,
      siteInfo: siteInfoDataResolver
    }
  },
  {
    path: Pages.LOGIN,
    component: LoginComponent,
    resolve: {
      siteInfo: siteInfoDataResolver
    },
  },
  {
    path: Pages.TOKEN_AUTH,
    loadComponent: () => import('src/app/page/token-auth/token-auth.component').then(m => m.TokenAuthComponent),
  },
  {
    path: Pages.FORGOT_PASSWORD,
    loadComponent: () => import('src/app/page/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent),
    resolve: {
      siteInfo: siteInfoDataResolver
    },
  },
  {
    path: Pages.SET_PASSWORD,
    loadComponent: () => import('src/app/page/set-password/set-password.component').then(m => m.SetPasswordComponent),
    resolve: {
      siteInfo: siteInfoDataResolver
    },
  },
  {
    path: Pages.MAINTENANCE,
    loadComponent: () => import('src/app/page/maintenance/maintenance.component').then(m => m.MaintenanceComponent),
    resolve: {
      siteInfo: siteInfoDataResolver
    },
  },
  {
    path: Pages.WELCOME,
    loadComponent: () => import('src/app/page/welcome/welcome.component').then(m => m.WelcomeComponent),
    resolve: {
      initialData: initialDataResolver,
      siteInfo: siteInfoDataResolver
    },
  },
  // {
  //   path: Pages.PRIVACY,
  //   loadComponent: () => import('app/page/privacy/privacy.component').then(m => m.PrivacyComponent),
  //   resolve: {
  //     initialData: initialDataResolver,
  //     siteInfo: siteInfoDataResolver
  //   },
  // },
  // {
  //   path: Pages.RISK,
  //   loadComponent: () => import('app/page/risk/risk.component').then(m => m.RiskComponent),
  //   resolve: {
  //     initialData: initialDataResolver,
  //     siteInfo: siteInfoDataResolver
  //   },
  // },
  // {
  //   path: Pages.IMPRINT,
  //   loadComponent: () => import('app/page/imprint/imprint.component').then(m => m.ImprintComponent),
  //   resolve: {
  //     initialData: initialDataResolver,
  //     siteInfo: siteInfoDataResolver
  //   },
  // },
  // {
  //   path: Pages.COMPANY + '/' + Pages.REGISTER,
  //   loadComponent: () => import('app/page/company/company-register-user/company-register-user.component').then(m => m.CompanyRegisterUserComponent),
  //   resolve: {
  //     siteInfo: siteInfoDataResolver
  //   },
  //   canActivate: [ companySiteGuard ]
  // },
  // {
  //   path: Pages.COMPANY + '/' + Pages.PORTAL,
  //   loadComponent: () => import('app/page/company/portal/portal.component').then(m => m.PortalComponent),
  //   resolve: {
  //     initialData: initialDataResolver,
  //     siteInfo: siteInfoDataResolver
  //   },
  //   canActivate: [ AuthGuard, CompanyAuthGuard, companySiteGuard ],
  //   children: portalRoutes
  // },
  // {
  //   path: Pages.HOST_ROOMS + '/:roomId',
  //   loadComponent: () => import('app/modules/project/ai/ai.component').then(m => m.AiComponent),
  //   resolve: {
  //     initialData: initialDataResolver,
  //     siteInfo: siteInfoDataResolver
  //   },
  //   canActivate: [ AuthGuard, companySiteGuard ],
  // },
  // {
  //   path: Pages.HISTORY,
  //   loadComponent: () => import('app/shared/component/history/history.component').then(m => m.HistoryComponent),
  //   resolve: {
  //     initialData: initialDataResolver,
  //     siteInfo: siteInfoDataResolver
  //   },
  //   canActivate: [ AuthGuard ],
  // },
  {
    path: Pages.CHAT,
    // loadComponent: () => import('src/app/page/chat/chat.component').then(m => m.ChatComponent),
    component: ChatComponent,
    resolve: {
      initialData: initialDataResolver,
      siteInfo: siteInfoDataResolver
    },
    canActivate: [ AuthGuard ],
  },
];
