<!--<ion-icon name="mic-outline" class="text-3xl cursor-pointer" (click)="startRecording()"></ion-icon>-->
<div class="flex flex-col items-center justify-center">
  <div class="stop-record-button" (mousedown)="$event.preventDefault()" (click)="startRecording()" [class.disabled]="disabled" [class.other]="other">
    @if (disabled) {
      <ion-icon name="mic-off" class="text-5xl"></ion-icon>
    } @else {
      <ion-icon name="mic" class="text-5xl"></ion-icon>
    }
  </div>
</div>
