<div class="flex flex-col w-full p-5 flex-auto overflow-y-auto text-base">
  <div class="w-full flex flex-col flex-auto">
    @if (!sent) {
      <app-alert [type]="'warning'" [appearance]="'fill'" class="mt-4">
        <div [innerHTML]="'text.delete.account' | transloco"></div>
      </app-alert>
    } @else {
      <div class="delete-result" [innerHTML]="'text.account.delete.request.sent' | transloco"></div>
    }
  </div>
  @if (!sent) {
    <div class="pt-5">
      <button class="button red w-full" [class.loading]="pending" [disabled]="pending" (click)="confirmAccountDeletion()">{{ 'button.delete.account' | transloco }}</button>
    </div>
  }
</div>
