import {Component, Input} from '@angular/core';
import {TranslocoPipe} from '@ngneat/transloco';

@Component({
  selector: 'app-qr-code-scanner-access-dialog',
  standalone: true,
  imports: [
    TranslocoPipe
  ],
  templateUrl: './qr-code-scanner-access-dialog.component.html',
  styleUrl: './qr-code-scanner-access-dialog.component.scss'
})
export class QrCodeScannerAccessDialogComponent {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  constructor() {
  }

  askAccess() {
    this.closeModal(true);
  }
}
