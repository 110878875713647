import { Component, inject, Input, Signal } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { UserService } from '@shared/service/user.service';
import { RoomUser } from '@shared/transport.interface';
import { UserState } from '@shared/store/user/user-state.service';
import { Store } from '@ngxs/store';
import { SaveUserAction } from '@shared/store/user/user.actions';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import { IonicModule } from '@ionic/angular';

@UntilDestroy()
@Component({
  selector: 'app-cancel-subscription',
  imports: [
    TranslocoPipe,
    IonicModule
  ],
  templateUrl: './reactivate-account.component.html',
  standalone: true,
  styleUrl: './reactivate-account.component.scss'
})
export class ReactivateAccountComponent {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  protected pending = false;

  private _userService = inject(UserService);
  private _store = inject(Store);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);

  constructor() {
    addIcons({ closeOutline });
  }

  reactivateAccount(): void {
    this.pending = true;
    this._userService.reactivateAccount()
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(() => {
        this.me().deleteTime = null;
        this._store.dispatch(new SaveUserAction(this.me()));
        this.closeModal();
      });
  }
}
