import { Component, EventEmitter, inject, Input, Output, Signal } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { RoomService } from '@shared/service/room.service';
import { NotyService } from '@shared/service/noty.service';
import { RoomUser } from '@shared/transport.interface';
import { UserState } from '@shared/store/user/user-state.service';
import { DialogService } from '@shared/service/dialog.service';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { mic, micOff } from 'ionicons/icons';
import { VoiceRecorder } from 'fpmk-capacitor-voice-recorder';
import { GenericResponse } from 'fpmk-capacitor-voice-recorder/dist/esm/definitions';
import { AudioService } from '@service/audio.service';

@Component({
  selector: 'app-record-button',
  standalone: true,
  imports: [
    TranslocoPipe,
    IonicModule
  ],
  templateUrl: './record-button.component.html',
  styleUrl: './record-button.component.scss'
})
export class RecordButtonComponent {
  @Input() lang: string;
  @Input() second: boolean = false;
  @Input() disabled: boolean = false;
  @Input() canChangeLang: boolean = true;

  @Output() selectLangEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() noMinutesEvent: EventEmitter<void> = new EventEmitter<void>();

  private readonly _dialog = inject(DialogService);
  private readonly _roomService = inject(RoomService);
  private readonly _store = inject(Store);
  private readonly _notyService = inject(NotyService);
  private readonly _audioService = inject(AudioService);
  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);

  constructor() {
    addIcons({ mic, micOff });
  }

  startRecording() {
    // this._audioService.initAudioForIos();
    this._audioService.stopAudio();
    if (!this.me().duration) {
      this.noMinutesEvent.next();
      return;
    }
    if (this.disabled) {
      this._notyService.error('text.select.language');
      return;
    }
    VoiceRecorder.requestAudioRecordingPermission().then((res: GenericResponse) => {
      if (res.value) {
        this._dialog.startRecord(!this.second, this.lang, this._roomService.mode()).subscribe();
      } else {
        this._dialog.microphoneAccess();
      }
    }).catch(error => console.log(error));
  }

  selectLanguage() {
    if (!this.canChangeLang) {
      return;
    }
    this.selectLangEvent.emit();
  }
}
