import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { finalize, Observable } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { UtilsService } from '@shared/service/utils.service';
import { DialogService } from '@shared/service/dialog.service';
import { IonicModule } from '@ionic/angular';
import { ChatResultType } from '@shared/transport.interface';
import { addIcons } from 'ionicons';
import { image, trash } from 'ionicons/icons';
import { environment } from '@env/environment';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { ImageResizeService } from '@service/image-resize.service';

@Component({
  selector: 'app-avatar',
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    NgTemplateOutlet,
    TranslocoPipe,
    IonicModule,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
  ],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AvatarComponent
    }
  ]
})
export class AvatarComponent implements ControlValueAccessor {
  @Input() initials: string = '';
  @Input() hasAvatar: boolean = false;
  @Input() canDelete: boolean = false;
  @Input() canEdit: boolean = true;
  @Input() file: string = '';
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  protected readonly _utilsService = inject(UtilsService);
  protected readonly _dialogService = inject(DialogService);
  private readonly _imageResizeService = inject(ImageResizeService);

  private lastFile: any;

  constructor() {
    addIcons({ image, trash });
  }

  writeValue(_file: string): void {
    this.file = environment.serverUrl + _file;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange = (fileUrl: string) => {
  };

  onTouched = () => {
  };

  disabled: boolean = false;

  onFileChange(event: any) {
    const files = event.target.files as FileList;

    if (files.length > 0) {
      this._imageResizeService.compressImage(files[ 0 ], 1024, 1024, 70).then((file: File) => {
        this.resetInput();
        this.lastFile = URL.createObjectURL(file);
        this.openAvatarEditor(this.lastFile)
          .pipe(finalize(() => URL.revokeObjectURL(this.lastFile)))
          .subscribe(
            (result) => {
              if (result) {
                this.file = result;
                this.hasAvatar = true;
                this.onChange(this.file);
              }
            }
          );
      });
    }
  }

  openAvatarEditor(image: string): Observable<any> {
    return this._dialogService.openAvatarEditor(image);
  }

  resetInput() {
    const input = document.getElementById('avatar-input-file') as HTMLInputElement;
    if (input) {
      input.value = "";
    }
  }

  removeAvatar(): void {
    this._dialogService.deleteAvatar()
      .subscribe(res => {
        this.file = '';
        this.onChange(this.file);
        this.hasAvatar = false;
      });
  }

  selectFile(): void {
    if (this.fileInput.nativeElement) {
      this.fileInput.nativeElement.click();
    }
  }

  protected readonly ChatResultType = ChatResultType;
  protected readonly environment = environment;
}
