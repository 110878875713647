import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { HttpClientService } from '../http-client/http-client.service';
import { catchError, Observable, of, switchMap } from 'rxjs';
import {
  AuthResult,
  BuyTariffPackageSession,
  HistoryFilter,
  Invoices,
  Notifications,
  Preferences,
  RoomProtocols,
  Rooms,
  RoomSummaries,
  RoomTranscripts,
  RoomUser,
  Tariff,
  TariffPackage
} from '../transport.interface';
import { ApiUrl } from '@shared/api-url';
import { SaveUserAction } from '@shared/store/user/user.actions';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { StorageService } from '@service/storage.service';

const moment = _rollupMoment || _moment;

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _store = inject(Store);
  private _storageService = inject(StorageService);

  constructor(private _http: HttpClientService) {
  }

  profile(): Observable<RoomUser> {
    this._storageService.accessToken().then(token => {
      if (!token) {
        return of({
            id: 0,
            email: '',
            name: '',
            language: '',
          }
        );
      }
    });
    return this._http.doGet<RoomUser>(ApiUrl.USER)
      .pipe(switchMap((user: RoomUser) => {
        this._store.dispatch(new SaveUserAction(user));
        return of(user);
      }))
      .pipe(catchError(error => {
        return of({
            id: 0,
            email: '',
            name: '',
            language: '',
          }
        );
      }));
  }

  updateLangs(mainLang: string, secondLang: string, swap?: boolean): Observable<RoomUser> {
    return this._http.doPatch<RoomUser>(ApiUrl.UPDATE_LANG, { language: mainLang, secondLanguage: secondLang, swap });
  }

  tariffs(): Observable<Tariff[]> {
    return this._http.doGet<Tariff[]>(ApiUrl.TARIFFS);
  }

  buyPackagesInfo(): Observable<TariffPackage[]> {
    return this._http.doGet<TariffPackage[]>(ApiUrl.TARIFF_PACKAGES()).pipe(switchMap(pack => {
      pack.forEach(p => {
        p.expiration = moment().add(p.expiration, 'M').valueOf();
      });
      return of(pack);
    }));
  }

  historyRooms(limit: number, offset: number, filter?: HistoryFilter): Observable<Rooms> {
    return this._http.doGetNoError<Rooms>(ApiUrl.HISTORY_ROOMS, { limit, offset, from: filter?.dateFilter?.fromDate, to: filter?.dateFilter?.toDate });
  }

  historySummaries(limit: number, offset: number, filter?: HistoryFilter): Observable<RoomSummaries> {
    return this._http.doGetNoError<RoomSummaries>(ApiUrl.HISTORY_SUMMARIES, { limit, offset, from: filter?.dateFilter?.fromDate, to: filter?.dateFilter?.toDate });
  }

  historyProtocols(limit: number, offset: number, filter?: HistoryFilter): Observable<RoomProtocols> {
    return this._http.doGetNoError<RoomProtocols>(ApiUrl.HISTORY_PROTOCOLS, { limit, offset, from: filter?.dateFilter?.fromDate, to: filter?.dateFilter?.toDate });
  }

  historyTranscripts(limit: number, offset: number, filter?: HistoryFilter): Observable<RoomTranscripts> {
    return this._http.doGetNoError<RoomTranscripts>(ApiUrl.HISTORY_TRANSCRIPTS, { limit, offset, from: filter?.dateFilter?.fromDate, to: filter?.dateFilter?.toDate });
  }

  buyTariff(tariffId: number): Observable<BuyTariffPackageSession> {
    return this._http.doPost<BuyTariffPackageSession>(ApiUrl.BUY_TARIFF(tariffId));
  }

  buyTariffPackage(tariffId: number, packageId: number): Observable<BuyTariffPackageSession> {
    return this._http.doPost<BuyTariffPackageSession>(ApiUrl.BUY_TARIFF_PACKAGE(tariffId, packageId));
  }

  invoices(limit: number, offset: number): Observable<Invoices> {
    return this._http.doGet<Invoices>(ApiUrl.INVOICES(), { limit, offset });
  }

  deleteAvatar(avatarId: string): Observable<void> {
    return this._http.doDelete<void>(ApiUrl.GET_AVATAR(avatarId));
  }

  changePassword(currentPassword: string, newPassword: string): Observable<void> {
    return this._http.doPost<void>(ApiUrl.CHANGE_PASSWORD, { currentPassword, newPassword });
  }

  updateProfile(firstName: string, lastName: string, language: string, position: string, positionDescription: string, avatar?: Blob): Observable<RoomUser> {
    const data = new FormData();
    data.set('firstName', firstName);
    data.set('lastName', lastName);
    data.set('language', language);
    data.set('companyRole', position);
    data.set('companyRoleDesc', positionDescription);
    if (avatar) {
      data.append("avatar", avatar);
    }
    return this._http.doPatch<RoomUser>(ApiUrl.USER, data);
  }

  updatePreferences(preferences: Preferences): Observable<RoomUser> {
    return this._http.doPatch<RoomUser>(ApiUrl.PREFERENCES, preferences);
  }

  updateNotifications(notifications: Notifications): Observable<RoomUser> {
    return this._http.doPatch<RoomUser>(ApiUrl.NOTIFICATIONS, notifications);
  }

  cancelTariff(): Observable<void> {
    return this._http.doDelete<void>(ApiUrl.CANCEL_SUBSCRIPTION);
  }

  tokenAuth(): Observable<AuthResult> {
    return this._http.doGet<AuthResult>(ApiUrl.TOKEN_AUTH);
  }

  usePromo(code: string): Observable<void> {
    return this._http.doGetNoError<void>(ApiUrl.USE_PROMO_CODE + code);
  }

  reactivateAccount(): Observable<void> {
    return this._http.doGetNoError<void>(ApiUrl.REACTIVATE_ACCOUNT);
  }

}
