@if (pack?.id) {
  <div class="border border-gray-300 p-4">
    <div class="flex justify-between pb-2.5 border-b border-b-gray-300">
      <div class="title text-4xl">
        {{ (pack.duration - (pack.bonus || 0)) | number: '1.0-0' }}&nbsp;{{ 'label.credits' | transloco }}
        @if (pack.bonus) {
          <span class="text-bonus">+&nbsp;{{ pack.bonus | number: '1.0-0' }}&nbsp;{{ 'label.bonus' | transloco }}</span>
        }
      </div>
      <div class="title text-4xl">{{ pack.price | currency: pack.currency }}</div>
    </div>
    <div class="flex mt-2.5">
      <div class="w-1/2">
        <div class="text-base">{{ 'title.cost.of.credit' | transloco }}</div>
        <div class="font-semibold text-lg">{{ (pack.price / pack.duration) | currency: pack.currency: 'symbol' :'1.3-3' }}</div>
      </div>
      <div class="w-1/2">
        @if (!isMobile()) {
          <div class="text-base">{{ 'title.expiration.date' | transloco }}</div>
          <div class="font-semibold text-lg">{{ pack.expiration | utcDate: 'dd.MM.yyyy' }}</div>
        }
      </div>
    </div>
    @if ((isWeb() || isMobile()) && pack.productId) {
      <div class="w-full mt-5">
        <button class="button w-full" (click)="buyMinutes(pack)" [class.loading]="pending" [disabled]="pending">{{ 'button.buy' | transloco }}</button>
      </div>
    }
  </div>
}
