<div class="flex">
  <div class="social-login" (click)="signInWithGoogle()">
    <!--    <div class="google-icon">&nbsp;</div>-->
    <ion-icon name="logo-google" class="text-3xl"></ion-icon>
  </div>

  @if (isIos) {
    <div class="social-login ml-4" (click)="signInWithApple()">
      <!--    <div class="apple-icon">&nbsp;</div>-->
      <ion-icon name="logo-apple" class="text-3xl"></ion-icon>
    </div>
  }
</div>
