import { inject, Injectable } from '@angular/core';
import { TariffPackage } from '@shared/transport.interface';
import { NotyService } from '@service/noty.service';
import { environment } from '@env/environment';
import { StorageService } from "@service/storage.service";
import { Capacitor } from '@capacitor/core';
import { DialogService } from '@service/dialog.service';
import { Platform } from '@ionic/angular';
import IError = CdvPurchase.IError;
import Product = CdvPurchase.Product;

@Injectable({
  providedIn: 'root'
})
export class InappPurchasesService {

  private readonly _noty = inject(NotyService);
  private readonly _storage = inject(StorageService);
  private readonly _dialogs = inject(DialogService);
  private readonly _platform = inject(Platform);

  private _store: CdvPurchase.Store;
  private _products: Product[] = [];
  private isIos = false;

  constructor() {
  }

  initStore(store: CdvPurchase.Store, products: TariffPackage[]) {
    this.isIos = Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';
    this._store = store;
    // this._store.verbosity = CdvPurchase.LogLevel.DEBUG;
    this._storage.accessToken().then(res => {
      this._store.validator = {
        url: environment.serverUrl + (this.isIos ? '/v1/user/package/verify' : '/v1/user/package/verify/google'),
        headers: {
          Authorization: res,
          'X-Authorization': res
        }
      };
    });
    const storeProducts = [];
    products.filter(product => !!product.productId).forEach(product => {
      storeProducts.push({
        id: product.productId,
        platform: this.isIos ? CdvPurchase.Platform.APPLE_APPSTORE : CdvPurchase.Platform.GOOGLE_PLAY,
        type: CdvPurchase.ProductType.CONSUMABLE,
      });
    });
    this._store.register(storeProducts);

    this._store.when()
      .productUpdated(() => {
        console.log('Products loaded from the store:', this._store.products);
        if (!this._products?.length) {
          this._products = this._store.products;
        }
      })
      .approved(transaction => {
        console.log('Purchase approved:', transaction);
        transaction.verify();
      })
      .verified(receipt => {
        console.log('Purchase verified:', receipt);
        receipt.finish();
        if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android') {
          this._dialogs.closeAll();
          this._noty.success('message.payment.successful');
        }
      });
    // Initialize the store
    this._store
      .initialize([
        {
          platform: this.isIos ? CdvPurchase.Platform.APPLE_APPSTORE : CdvPurchase.Platform.GOOGLE_PLAY,
          options: {
            needAppReceipt: true,
          }
        }
      ])
      .catch(reason => {
        console.error('Store init error:', reason);
      });
  }

  buyPackage(packageId: string): Promise<IError | undefined> {
    const product = this._store.get(packageId);
    if (!product) {
      this._noty.error('error.package.not.found');
      return;
    }
    return product.getOffer()?.order();
  }

  products() {
    return this._products;
  }
}
