import { Component, Input, OnInit } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { TranslocoPipe } from '@ngneat/transloco';
import { Clipboard } from '@capacitor/clipboard';
import { QrCodeComponent } from '../../ui/qr-code/qr-code.component';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { closeOutline, copyOutline, shareOutline } from 'ionicons/icons';
import { environment } from '@env/environment';
import { Share } from '@capacitor/share';

@Component({
  selector: 'app-qr-code-dialog',
  standalone: true,
  imports: [
    QrCodeComponent,
    MatIconButton,
    TranslocoPipe,
    IonicModule,
  ],
  templateUrl: './qr-code-dialog.component.html',
  styleUrl: './qr-code-dialog.component.scss'
})
export class QrCodeDialogComponent implements OnInit {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  protected url: string = environment.serverUrl + '/join-room/';

  roomId: string;
  isCompany: boolean;

  protected readonly navigator = navigator;

  constructor() {
    addIcons({ closeOutline, copyOutline, shareOutline });
  }

  ngOnInit(): void {
    if (this.data) {
      this.roomId = this.data.roomId;
      this.url += this.roomId;
      this.isCompany = this.data.isCompany;
    }
  }

  async shareUrl() {
    await Share.share({
      title: 'FINEXWORD: Speak freely. Translate Instantly.',
      url: this.url,
      dialogTitle: 'Share',
    });
  }

  copyUrlFlag = false;

  copyUrl(): void {
    Clipboard.write({ string: this.url }).then();
    this.copyUrlFlag = true;
    setTimeout(() => this.copyUrlFlag = false, 2000);
  }
}
